import { Directive,Input,HostListener } from '@angular/core';
import { AnalyticsService } from '../service/analytics.service';

@Directive({
  selector: '[appLinkClick]'
})
export class LinkClickDirective {
  @Input() appLinkClick;
  constructor(public analytics: AnalyticsService) { }
  @HostListener('click') onMouseEnter() {
    //this.analytics.linkClickEvent(this.appLinkClick);
  }
}
