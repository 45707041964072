import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard, UserGuard } from './service/auth.guard';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'find-care',
    loadChildren: () => import('./find-care/find-care.module').then( m => m.FindCarePageModule)
  },
  {
    path: 'provider-results',
    loadChildren: () => import('./result/result.module').then( m => m.ResultPageModule),
    //runGuardsAndResolvers: 'always'
  },
  {
    path: 'provider-detail',
    loadChildren: () => import('./profile-detail/profile-detail.module').then( m => m.ProfileDetailPageModule)
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./signin/signin.module').then( m => m.SigninPageModule)
  },
  {
    path: 'pagenotfound',
    loadChildren: () => import('./pagenotfound/pagenotfound.module').then( m => m.PagenotfoundPageModule)
  },
  {
    path: 'a-to-z-directory',
    loadChildren: () => import('./atoz-directory/atoz-directory.module').then( m => m.AtozDirectoryPageModule)
  },
  {
    path: 'compare-provider',
    loadChildren: () => import('./compare-provider/compare-provider.module').then( m => m.CompareProviderPageModule)
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./profile-settings/profile-settings.module').then( m => m.ProfileSettingsPageModule)
  },
  {
    path: 'search-for-providers',
    loadChildren: () => import('./mobile-searchpage/mobile-searchpage.module').then( m => m.MobileSearchpagePageModule)
  },
  {
    path: 'my-benefits',
    loadChildren: () => import('./benifits-sync/benifits-sync.module').then( m => m.BenifitsSyncPageModule),
    canActivate: [UserGuard],
    data: {
      benefit: 'yes'
    }
  },
  {
    path: 'fav-provider',
    loadChildren: () => import('./fav-provider/fav-provider.module').then( m => m.FavProviderPageModule)
  },
  {
    path: 'profile-info',
    loadChildren: () => import('./profile-info/profile-info.module').then( m => m.ProfileInfoPageModule)
  },
  {
    path: 'cost-of-care-calculator',
    loadChildren: () => import('./cost-calculator/cost-calculator.module').then( m => m.CostCalculatorPageModule)
  },
  {
    path: 'find-shoppable-services',
    loadChildren: () => import('./view-services/view-services.module').then( m => m.ViewServicesPageModule)
  },
  {
    path: 'cost-search',
    loadChildren: () => import('./cost-search/cost-search.module').then( m => m.CostSearchPageModule)
  },
  {
    path: 'cost-result',
    loadChildren: () => import('./cost-result/cost-result.module').then( m => m.CostResultPageModule)
  },
  {
    path: 'create-account',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'personal-profile',
    loadChildren: () => import('./personal-profile/personal-profile.module').then( m => m.PersonalProfilePageModule)
  },
  {
    path: 'about-me',
    loadChildren: () => import('./about-me/about-me.module').then( m => m.AboutMePageModule)
  },
  {
    path: 'user-management',
    loadChildren: () => import('./user-management/user-management.module').then( m => m.UserManagementPageModule)
  },
  {
    path: 'admin-home',
    loadChildren: () => import('./admin/admin-home/admin-home.module').then( m => m.AdminHomePageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'ADMIN'
    }
  },
  {
    path: 'admin-profile',
    loadChildren: () => import('./admin/admin-profile/admin-profile.module').then( m => m.AdminProfilePageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'ADMIN'
    }
  },
  {
    path: 'caremetriq',
    loadChildren: () => import('./admin/caremetriq/caremetriq.module').then( m => m.CaremetriqPageModule)
  },
  {
    path: 'review-guidelines',
    loadChildren: () => import('./review-guidelines/review-guidelines.module').then( m => m.ReviewGuidelinesPageModule)
  },
  {
    path: 'tip-message',
    loadChildren: () => import('./admin/tip-message/tip-message.module').then( m => m.TipMessagePageModule)
  },
  {
    path: 'invite-family',
    loadChildren: () => import('./invite-family/invite-family.module').then( m => m.InviteFamilyPageModule)
  },
  {
    path: 'sdoh-data',
    loadChildren: () => import('./admin/sdoh-data/sdoh-data.module').then( m => m.SdohDataPageModule),
    canActivate: [AuthGuard],
    data: {
      role: 'ADMIN'
    }
  },












];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: "reload", preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
