import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import awsconfig from './aws-exports';
import Amplify from 'aws-amplify';
import { Analytics } from 'aws-amplify';
//Analytics.disable();
Amplify.configure(awsconfig);

const awsmobile = {
  //"aws_project_region": "us-east-1",
  //"aws_appsync_graphqlEndpoint": "https://a5slh7vrdretfghd2ocde6twem.appsync-api.us-east-1.amazonaws.com/graphql",
  //"aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  //"aws_appsync_apiKey": "da2-eddvukjwhjapvkjlmh3s7hnrqy",
  //"aws_cognito_identity_pool_id": "us-east-1:0235ea59-9304-4d0e-8a4e-9836872fdb5f",
  "aws_cognito_region": "us-east-1",
  //"aws_user_pools_id": "us-east-1_zsbpZFgb9",
  //"aws_user_pools_web_client_id": "720hbgs4qn3p8jd1khl4sai933",
};
Amplify.configure(awsmobile);

/*var hostedUI = localStorage.getItem("amplify-signin-with-hostedUI");
if(!hostedUI || hostedUI == "true"){
  var samluserPool = environment.userPool.find(x => x.domain === "openid");
  if(samluserPool) {
    Amplify.configure(samluserPool);
  }else{
    var userPool = environment.userPool.find(x => x.domain === "carematch");
    Amplify.configure(userPool);
  }
}*/

Amplify.configure({
  API: {
    graphql_headers: async () => ({
      'Referrer-Policy': 'no-referrer',
      'Cache-Control':  'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache'
    })
  }
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
