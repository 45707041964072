import { NgModule } from '@angular/core';

import { LinkClickDirective } from './link-click.directive';
import { TranslatePipe } from '../translate.pipe';

@NgModule({
  imports: [],
  declarations: [
    LinkClickDirective,
    TranslatePipe
  ],
  exports: [
    LinkClickDirective,
    TranslatePipe
  ]
})
export class DirectiveModule {}
