import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { Auth } from 'aws-amplify';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private alertCtrl: AlertController) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const expectedRole = route.data?.role || null;
    console.log(expectedRole);
    if(expectedRole){
      if(localStorage.userRole == expectedRole)
        return true;
      else{
        this.showAlert();
        return this.router.parseUrl('/home');
      }
    }else{
      return true;
    }
  }
  async showAlert() {
    let alert = await this.alertCtrl.create({
      cssClass: 'custom-alert',
      message: 'You are not authorized to visit that page!',
      buttons: ['OK']
    });
    alert.present();
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {
  constructor(private router: Router, private alertCtrl: AlertController) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const expectedRole = route.data?.benefit || null;
    console.log(expectedRole);
    if(expectedRole){
      var poolName = localStorage.userPool;
      if(poolName){
        var userPool = environment.userPool.find(x => x.domain === poolName);
        var benefitShow = (userPool["d2c"] && userPool["d2c"].toLowerCase()==expectedRole)?false:true;
        if(benefitShow)
          return true;
        else{
          this.showAlert();
          return this.router.parseUrl('/home');
        }
      } 
    }else{
      this.showAlert();
      return this.router.parseUrl('/home');
    }
  }
  async showAlert() {
    let alert = await this.alertCtrl.create({
      cssClass: 'custom-alert',
      message: 'You are not authorized to visit that page!',
      buttons: ['OK']
    });
    alert.present();
  }
  
}
