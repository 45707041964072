import { Injectable } from '@angular/core';
import { userInfo } from 'os';

@Injectable({
  providedIn: 'root'
})
export class DataTransferService {
  filters:any={};
  mapKey:any="";
  userInfo:any={};
  //data:any="";
  constructor() { }
  getFilters(){
    return this.filters;
  }
  setFilters(obj){
    this.filters = obj;
  }
  setMapquestKey(value){
    this.mapKey = value;
  }
  getMapquestKey(){
    return this.mapKey;
  }
  getUser(){
    return this.userInfo;
  }
  setUser(obj){
    this.userInfo = obj;
  }
  // addData(text){
  //   this.data = this.data+"\n"+text;
  //   console.log(this.data);
  // }
}
