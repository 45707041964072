import { Component, Input,OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
    selector: 'popover',
    templateUrl: './popover.page.html',
    styleUrls: ['./popover.page.scss'],
  })
  export class Popover implements OnInit {
    @Input() compare: string;
    @Input() favProvider: string;
    constructor(public popoverController: PopoverController){ }
    ngOnInit(){
      console.log(this.favProvider,this.compare);
    }
    favProv(value){
      this.popoverController.dismiss(value);
    }
  }