import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { APIService } from './API.service';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'translateLang'
})
export class TranslatePipe implements PipeTransform {
  private transText: any = null;
  constructor(public service:Service){}
  transform(value: any): any {
    if(localStorage.transLang && localStorage.transLang !="en" && value){
      return this.service.getTranslateText(value).pipe(
        map(response => {
          return response;
        })
      );
    }else{
      return from((async () => {
        return value;
      })());
    }
  }
}


@Injectable({
  providedIn: 'root'
})
export class Service {

  constructor(private apiService: APIService) { }
  getTranslateText(value) {
    var transLang = localStorage.transLang?localStorage.transLang:'en';
    return from(this.apiService.GetTranslateText(value,transLang,"en").then(data=>{
      return data?.result?.TranslatedText;
    }));
  }
}