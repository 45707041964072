/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { float } from "aws-sdk/clients/cloudfront";
import { Observable } from "zen-observable-ts";

export type paginationField = {
  limit?: number | null;
  page?: number | null;
};

export type sortFiled = {
  column?: string | null;
  order?: string | null;
};

export type procedureTab = {
  __typename: "procedureTab";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<ProcedureTabData | null> | null;
};

export type SuccessResponse = {
  __typename: "SuccessResponse";
  statusCode: number;
  msg: string;
};

export type PageResponse = {
  __typename: "PageResponse";
  totalCount: number;
  pageLimit: number;
  page: number;
  offset: number;
  totalRecordView: number;
};

export type ProcedureTabData = {
  __typename: "ProcedureTabData";
  taxList?: string | null;
  displayName?: string | null;
  description?: string | null;
};

export type SearchPhrase = {
  __typename: "SearchPhrase";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<SearchPhraseData | null> | null;
};

export type SearchPhraseData = {
  __typename: "SearchPhraseData";
  DisplayName?: string | null;
  PhraseType?: string | null;
  ProviderGroup?: string | null;
  SearchType?: number | null;
  TypeID?: number | null;
  TaxList?: string | null;
  Npi?: string | null;
  Ccn?: string | null;
};

export type oSSearchPhrase = {
  __typename: "oSSearchPhrase";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: string | null;
};

export type NpiHospitalInfoByPcTc = {
  __typename: "NpiHospitalInfoByPcTc";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: NpiHospitalInfoByPcTcDataObj | null;
};

export type NpiHospitalInfoByPcTcDataObj = {
  __typename: "NpiHospitalInfoByPcTcDataObj";
  filterData?: Array<NpiHospitalInfoByPcTcData | null> | null;
  findatDistanceKm?: string | null;
  hospitalSysFilter?: Array<hospitalAffFilterobj | null> | null;
  hospitalFacilityFilter?: Array<hospitalAffFilterobj | null> | null;
  facilityTypeFilter?: Array<hospitalAffFilterobj | null> | null;
};

export type NpiHospitalInfoByPcTcData = {
  __typename: "NpiHospitalInfoByPcTcData";
  ccn?: string | null;
  facilityID?: string | null;
  facilityName?: string | null;
  facilityType?: string | null;
  phone?: string | null;
  clia?: string | null;
  hospitalName?: string | null;
  medicalSchoolAffiliation?: string | null;
  caseMixIndex?: string | null;
  hospitalType?: string | null;
  simbiqScore?: string | null;
  primaryNearest?: string | null;
  cityState?: string | null;
  primaryLocation?: ProviderListPrimaryLocation | null;
  primaryTransit?: Array<primaryTransit | null> | null;
  centerPoint?: string | null;
  provType?: string | null;
};

export type ProviderListPrimaryLocation = {
  __typename: "ProviderListPrimaryLocation";
  locationAddress?: string | null;
  locationName?: string | null;
  milesAway?: string | null;
  phone?: string | null;
  lat?: number | null;
  lng?: number | null;
};

export type primaryTransit = {
  __typename: "primaryTransit";
  stop_name?: string | null;
  stop_desc?: string | null;
  stop_lat?: string | null;
  stop_lon?: string | null;
  stop_url?: string | null;
  wheelchair_boarding?: string | null;
  vehicle_type?: string | null;
  stop_addres?: string | null;
  milesAway?: string | null;
};

export type hospitalAffFilterobj = {
  __typename: "hospitalAffFilterobj";
  name: string;
};

export type getResponseEmbededUrl = {
  __typename: "getResponseEmbededUrl";
  statusCode?: number | null;
  body?: string | null;
};

export type standardCharge = {
  __typename: "standardCharge";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<standardChargeResponse | null> | null;
};

export type standardChargeResponse = {
  __typename: "standardChargeResponse";
  code?: string | null;
  codeDesc?: string | null;
  codeType?: string | null;
};

export type inStatndardInNetwork = {
  __typename: "inStatndardInNetwork";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<inStatndardInNetworkData | null> | null;
};

export type inStatndardInNetworkData = {
  __typename: "inStatndardInNetworkData";
  payerName?: string | null;
  plans?: Array<plansObj | null> | null;
};

export type plansObj = {
  __typename: "plansObj";
  planName?: string | null;
};

export type filterByObj = {
  payerName?: string | null;
  planName?: string | null;
};

export type getStandardChargeData = {
  __typename: "getStandardChargeData";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<getStandardChargeDataResponse | null> | null;
};

export type getStandardChargeDataResponse = {
  __typename: "getStandardChargeDataResponse";
  svcCodeDescription?: string | null;
  code_1?: string | null;
  code_1_type?: string | null;
  code_2?: string | null;
  code_2_type?: string | null;
  code_3?: string | null;
  code_3_type?: string | null;
  code_4?: string | null;
  code_4_type?: string | null;
  modifierCode?: string | null;
  setting?: string | null;
  drug_unit_of_measurement?: string | null;
  drug_type_of_measurement?: string | null;
  standardGrossCharge?: string | null;
  standard_charge_discounted_cash?: string | null;
  payerName?: string | null;
  planName?: string | null;
  standard_charge_negotiated_dollar?: string | null;
  standard_charge_negotiated_percentage?: string | null;
  standard_charge_negotiated_algorithm?: string | null;
  estimated_amount?: string | null;
  standard_charge_methodology?: string | null;
  minCharge?: string | null;
  maxCharge?: string | null;
  additional_generic_notes?: string | null;
  hospital_name?: string | null;
  hospital_address?: string | null;
  facilityCCN?: string | null;
  facilityID?: string | null;
  ccnResultsFacility?: Array<ccnResultsFacilityObj | null> | null;
};

export type svcCode = {
  __typename: "svcCode";
  svcCode?: string | null;
  svcCodeCategory?: string | null;
};

export type payerInformationObj = {
  __typename: "payerInformationObj";
  payerName?: string | null;
  planName?: string | null;
};

export type ccnResultsFacilityObj = {
  __typename: "ccnResultsFacilityObj";
  ccn?: string | null;
  city?: string | null;
  phone?: string | null;
  state?: string | null;
  street?: string | null;
  zipCode?: string | null;
  facilityID?: string | null;
  facilityName?: string | null;
  facilityType?: string | null;
  facilitySummary?: string | null;
  type?: string | null;
  coordinates?: coordinatesObj | null;
  nearestKm?: number | null;
};

export type coordinatesObj = {
  __typename: "coordinatesObj";
  lat?: number | null;
  lon?: number | null;
};

export type parameterPass = {
  __typename: "parameterPass";
  status?: SuccessResponse | null;
  result?: Array<parameterPassData | null> | null;
};

export type parameterPassData = {
  __typename: "parameterPassData";
  Name: string;
  Type: string;
  Value: string;
};

export type taxListsByShortcut = {
  __typename: "taxListsByShortcut";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<taxLists | null> | null;
};

export type taxLists = {
  __typename: "taxLists";
  taxList: string;
};

export type userSettingResponse = {
  __typename: "userSettingResponse";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<settingResponse | null> | null;
};

export type settingResponse = {
  __typename: "settingResponse";
  DefaultLanguage: string;
  TextNewResultSavedSearch: number;
  TextFavoriteAcceptingNew: number;
  TextAppointmentReminder: number;
  EmailNewResultSavedSearch: number;
  EmailFavoriteAcceptingNew: number;
  EmailAppointmentReminder: number;
};

export type getdomainSourceResponse = {
  __typename: "getdomainSourceResponse";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<domainUserSource | null> | null;
};

export type domainUserSource = {
  __typename: "domainUserSource";
  userSdohInfo?: Array<userSdohInfoData | null> | null;
  sourceSdoh?: Array<sourceSdohData | null> | null;
};

export type userSdohInfoData = {
  __typename: "userSdohInfoData";
  subId?: string | null;
  userPoolId?: string | null;
  aboutSdoh?: Array<aboutSdohData | null> | null;
};

export type aboutSdohData = {
  __typename: "aboutSdohData";
  domainName?: string | null;
  status?: number | null;
  correctAnswers?: Array<correctAnswersdata | null> | null;
};

export type correctAnswersdata = {
  __typename: "correctAnswersdata";
  questionCode?: string | null;
  answerCode?: string | null;
};

export type sourceSdohData = {
  __typename: "sourceSdohData";
  domainName?: string | null;
  questionAnswerInfo?: Array<questionAnswerInfoSource | null> | null;
};

export type questionAnswerInfoSource = {
  __typename: "questionAnswerInfoSource";
  questionCode?: string | null;
  question?: string | null;
  answerType?: string | null;
  answers?: Array<answersData | null> | null;
};

export type answersData = {
  __typename: "answersData";
  answerCode?: string | null;
  answer?: string | null;
};

export type getTranslateTextResponse = {
  __typename: "getTranslateTextResponse";
  status?: SuccessResponse | null;
  result?: translateTextObj | null;
};

export type translateTextObj = {
  __typename: "translateTextObj";
  TranslatedText?: string | null;
  SourceLanguageCode?: string | null;
  TargetLanguageCode?: string | null;
};

export type getdomainStatusResponse = {
  __typename: "getdomainStatusResponse";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<domainNames | null> | null;
};

export type domainNames = {
  __typename: "domainNames";
  status?: number | null;
  domainName?: string | null;
};

export type domainAnswersData = {
  questionCode?: string | null;
  answerCode?: string | null;
};

export type userUpdateProfileSettingResponse = {
  __typename: "userUpdateProfileSettingResponse";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
};

export type userBenefitRecommendationResponse = {
  __typename: "userBenefitRecommendationResponse";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<benefitRecommedationData | null> | null;
};

export type benefitRecommedationData = {
  __typename: "benefitRecommedationData";
  benefits?: Array<benefitObjData | null> | null;
};

export type benefitObjData = {
  __typename: "benefitObjData";
  benefitID?: number | null;
  benefitName?: string | null;
  benefitLogo?: string | null;
  benefitType?: string | null;
  benefitSummary?: string | null;
  benefitMemberID?: string | null;
  benefitSubscriberID?: string | null;
  benefitCost?: string | null;
  benefitGroupID?: string | null;
  benefitWebsite?: string | null;
  benefitWebsiteDisplayName?: string | null;
  benefitPhone?: string | null;
  benefitLoginInstructions?: string | null;
};

export type communicationPreferenceObj = {
  email?: number | null;
  text?: number | null;
};

export type userAttributesObj = {
  accessCode?: string | null;
  subId?: string | null;
  employeeID?: string | null;
};

export type userProfileInputData = {
  memberId?: string | null;
  subId?: string | null;
  userType?: string | null;
  clientName?: string | null;
  memberAccessCode?: string | null;
  memberCity?: string | null;
  memberDOB?: string | null;
  benefitStartDate?: string | null;
  benefitendDate?: string | null;
  benefitCost?: string | null;
  memberEmailConsent?: string | null;
  memberPersonalEmail?: string | null;
  memberFirstName?: string | null;
  memberFullName?: string | null;
  memberSsnCode?: string | null;
  memberGenderIdentity?: string | null;
  memberHireDate?: string | null;
  memberLastName?: string | null;
  memberPhone?: string | null;
  memberPhoneType?: string | null;
  memberPreferredLanguage?: string | null;
  memberPreferredName?: string | null;
  memberSex?: string | null;
  memberStreetAddress1?: string | null;
  memberStreetAddress2?: string | null;
  memberTermDate?: string | null;
  memberTextConsent?: string | null;
  memberWorkEmail?: string | null;
  memberZipCode?: string | null;
  simbiqAccessCode?: string | null;
  subscriberFlag?: string | null;
  subscriberPlanType?: string | null;
  race?: string | null;
  ethnicity?: string | null;
  childDependentFlag?: string | null;
  elderDependentFlag?: string | null;
};

export type userProfileGetResponse = {
  __typename: "userProfileGetResponse";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<userProfileGetData | null> | null;
};

export type userProfileGetData = {
  __typename: "userProfileGetData";
  clientId?: string | null;
  memberId?: string | null;
  communication?: communicationPreferenceData | null;
  location?: string | null;
  poolId?: string | null;
  subId?: string | null;
  subscribeId?: string | null;
  tenantId?: string | null;
  clientName?: string | null;
  memberAccessCode?: string | null;
  memberFullName?: string | null;
  memberSsnCode?: string | null;
  memberCity?: string | null;
  memberDOB?: string | null;
  memberEmailConsent?: string | null;
  memberPersonalEmail?: string | null;
  memberFirstName?: string | null;
  memberGenderIdentity?: string | null;
  memberHireDate?: string | null;
  memberLastName?: string | null;
  memberPhone?: string | null;
  memberPhoneType?: string | null;
  memberPreferredLanguage?: string | null;
  memberPreferredName?: string | null;
  memberSex?: string | null;
  memberStreetAddress1?: string | null;
  memberStreetAddress2?: string | null;
  memberTermDate?: string | null;
  memberTextConsent?: string | null;
  memberWorkEmail?: string | null;
  memberZipCode?: string | null;
  simbiqAccessCode?: string | null;
  subscriberFlag?: string | null;
  subscriberPlanType?: string | null;
  race?: string | null;
  ethnicity?: string | null;
  childDependentFlag?: string | null;
  elderDependentFlag?: string | null;
  userBenefits?: Array<benefitObjData | null> | null;
};

export type communicationPreferenceData = {
  __typename: "communicationPreferenceData";
  email?: number | null;
  text?: number | null;
};

export type userTenantConfigResponse = {
  __typename: "userTenantConfigResponse";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<elementsObjData | null> | null;
};

export type elementsObjData = {
  __typename: "elementsObjData";
  primaryFont?: string | null;
  pageTitle?: string | null;
  subTitle?: string | null;
  topBackground?: string | null;
  placeHolder?: string | null;
  textBlack?: string | null;
  iconHover?: string | null;
  borderGray?: string | null;
  cardBorder?: string | null;
  cardHover?: string | null;
  iconColor?: string | null;
  linkHover?: string | null;
  buttonHover?: string | null;
  filterChip?: string | null;
  filterchipBorder?: string | null;
  filterchipActive?: string | null;
  fchipactiveBorder?: string | null;
};

export type addelementsObjData = {
  primaryFont?: string | null;
  pageTitle?: string | null;
  subTitle?: string | null;
  topBackground?: string | null;
  placeHolder?: string | null;
  textBlack?: string | null;
  iconHover?: string | null;
  borderGray?: string | null;
  cardBorder?: string | null;
  cardHover?: string | null;
  iconColor?: string | null;
  linkHover?: string | null;
  buttonHover?: string | null;
  filterChip?: string | null;
  filterchipBorder?: string | null;
  filterchipActive?: string | null;
  fchipactiveBorder?: string | null;
};

export type userUpdateResponse = {
  __typename: "userUpdateResponse";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
};

export type userBenefitResponse = {
  __typename: "userBenefitResponse";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<benefitData | null> | null;
};

export type benefitData = {
  __typename: "benefitData";
  benefits?: Array<benefitObjData | null> | null;
  clientData?: userPoolClientData | null;
};

export type userPoolClientData = {
  __typename: "userPoolClientData";
  deptName?: string | null;
  tenantName?: string | null;
  availableHrs?: string | null;
  phone?: string | null;
  phoneInternal?: string | null;
  email?: string | null;
};

export type userFavourites = {
  __typename: "userFavourites";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: favProviderData | null;
};

export type favProviderData = {
  __typename: "favProviderData";
  providers?: Array<NpiInfoByPcTcData | null> | null;
  facilities?: Array<providerFacilityDataNew | null> | null;
};

export type NpiInfoByPcTcData = {
  __typename: "NpiInfoByPcTcData";
  ProviderNPI?: string | null;
  entityCode?: number | null;
  licenseState?: string | null;
  providerName?: string | null;
  medicalGraduationDate?: string | null;
  provYearsPracticing?: number | null;
  simbiqScore?: string | null;
  gender?: string | null;
  nearestKm?: string | null;
  nearestLocation?: string | null;
  primarySpecialty?: string | null;
  provOrgname?: string | null;
  acceptingNewPatients?: string | null;
  translationService?: string | null;
  faciliyAddress?: string | null;
  mipsScore?: number | null;
  placeHolderText?: string | null;
  primaryLocation?: ProviderListPrimaryLocation | null;
  primaryTransit?: Array<primaryTransit | null> | null;
  practiseResult?: Array<practiceData | null> | null;
  otherLocationCount?: string | null;
  centerPoint?: string | null;
  boardCardsName?: string | null;
  disciplinaryAction?: Array<disciplinaryActionData | null> | null;
  provType?: string | null;
};

export type practiceData = {
  __typename: "practiceData";
  locationName: string;
  locationAddress?: string | null;
  milesAway?: number | null;
  lat?: string | null;
  lng?: string | null;
  locationType: string;
};

export type disciplinaryActionData = {
  __typename: "disciplinaryActionData";
  provBoardAction?: string | null;
  provBoardActionDate?: string | null;
  provLicenseState?: string | null;
};

export type providerFacilityDataNew = {
  __typename: "providerFacilityDataNew";
  ccn?: string | null;
  facilityID?: string | null;
  facilityName?: string | null;
  facilityType?: string | null;
  facilitySummary?: string | null;
  costEstimatorLink?: string | null;
  standardChargeLink?: string | null;
  primaryLocation?: ProviderListPrimaryLocation | null;
  phone?: string | null;
  affiliatedProviders?: Array<affiliatedProviders | null> | null;
  services?: Array<facilityavailableServices | null> | null;
  departments?: Array<facilityavailableServices | null> | null;
  drgList?: Array<drgList | null> | null;
  primaryTransit?: Array<primaryTransit | null> | null;
  measurements?: Array<measurements | null> | null;
  hospitalRatingCount?: hospitalRatingCountObj | null;
  type?: string | null;
};

export type affiliatedProviders = {
  __typename: "affiliatedProviders";
  locationAddress?: string | null;
  providerName?: string | null;
};

export type facilityavailableServices = {
  __typename: "facilityavailableServices";
  name?: string | null;
};

export type drgList = {
  __typename: "drgList";
  DRGNo?: number | null;
  DRGName?: string | null;
  DRGDischarges?: string | null;
  DRGRegionalDischargePct?: string | null;
};

export type measurements = {
  __typename: "measurements";
  measureCode?: string | null;
  measureName?: string | null;
  score?: string | null;
  measureDateRange?: string | null;
};

export type hospitalRatingCountObj = {
  __typename: "hospitalRatingCountObj";
  hospitalOwnership?: string | null;
  emergencyServices?: string | null;
  hospitalOverallRating?: string | null;
  safetyGroupMeasureCount?: string | null;
  readmGroupMeasureCount?: string | null;
  ptExpGroupMeasureCount?: string | null;
  teGroupMeasureCount?: string | null;
};

export type userSearches = {
  __typename: "userSearches";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<favSavedSearchData | null> | null;
};

export type favSavedSearchData = {
  __typename: "favSavedSearchData";
  keyword?: string | null;
  filters?: filterOptions | null;
  taxList?: string | null;
  date?: string | null;
};

export type filterOptions = {
  __typename: "filterOptions";
  distance?: string | null;
  gender?: string | null;
  simbiqScore?: string | null;
  acceptingNewPatients?: string | null;
};

export type filterField = {
  simbiqScore?: string | null;
  gender?: string | null;
  distance?: string | null;
  translationServices?: string | null;
  acceptingNewPatients?: string | null;
  hospitalAffFilter?: string | null;
  hospitalSysFilter?: string | null;
  hospitalGroupFilter?: string | null;
  hospitalFacilityFilter?: string | null;
  facilityTypeFilter?: string | null;
  boardCertFilter?: string | null;
  medicalSchoolAffiliation?: string | null;
  acuteFlag?: string | null;
  nearTransit?: string | null;
};

export type NpiInfoByPcTc = {
  __typename: "NpiInfoByPcTc";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: NpiInfoByPcTcDataArray | null;
};

export type NpiInfoByPcTcDataArray = {
  __typename: "NpiInfoByPcTcDataArray";
  filterData?: Array<NpiInfoByPcTcData | null> | null;
  findatDistanceKm?: string | null;
  hospitalAffFilter?: Array<hospitalAffFilterobj | null> | null;
  hospitalSysFilter?: Array<hospitalAffFilterobj | null> | null;
  specialtyFilter?: Array<specialtyFilterObj | null> | null;
  hospitalGroupFilter?: Array<hospitalAffFilterobj | null> | null;
  boardCertFilter?: Array<hospitalAffFilterobj | null> | null;
};

export type specialtyFilterObj = {
  __typename: "specialtyFilterObj";
  name?: string | null;
  taxCode?: string | null;
  type?: string | null;
};

export type SpecialityTab = {
  __typename: "SpecialityTab";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<SpecialityTabData | null> | null;
};

export type SpecialityTabData = {
  __typename: "SpecialityTabData";
  specialtycode?: string | null;
  specialtyname?: string | null;
  description?: string | null;
  subSpecresult?: Array<subSpecData | null> | null;
};

export type subSpecData = {
  __typename: "subSpecData";
  subspecialtycode: string;
  subspecialtyname: string;
  description: string;
};

export type cityStateInfo = {
  __typename: "cityStateInfo";
  status?: SuccessResponse | null;
  result?: cityStateData | null;
};

export type cityStateData = {
  __typename: "cityStateData";
  zipcode: string;
};

export type ProviderDetail = {
  __typename: "ProviderDetail";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<providerData | null> | null;
};

export type providerData = {
  __typename: "providerData";
  npi: string;
  entityCode: string;
  licenseState?: string | null;
  year?: string | null;
  phoneNumber?: string | null;
  primaryZipCode?: string | null;
  primaryTopLocation?: string | null;
  primaryMilesAway?: number | null;
  medicalGraduationDate?: string | null;
  provYearsPracticing?: number | null;
  translationService?: string | null;
  licenseNumber?: string | null;
  providerName: string;
  proFirst: string;
  proMiddle: string;
  proLast: string;
  provName: string;
  provGender: string;
  medicalGraduation?: string | null;
  provTaxList: string;
  medicalGraduationSchool?: string | null;
  acceptingNewPatients?: string | null;
  provPostGradList?: Array<gradList | null> | null;
  provHonors?: Array<honors | null> | null;
  provPublications?: Array<publication | null> | null;
  provOrgName: string;
  provGroupSpecialtyType?: string | null;
  provGroupName?: string | null;
  primarySpecialty?: string | null;
  simbiqScore?: number | null;
  provInsuranceAccepted?: string | null;
  provAcceptingMedicaid?: string | null;
  provMedicareParticipating?: string | null;
  provLocationList?: Array<provLocCompare | null> | null;
  provGroupAffiliationList?: Array<provGroupHospObj | null> | null;
  provHospitalAffiliationList?: Array<provGroupHospObj | null> | null;
  provSystemAffiliationList?: Array<provSysHealthObj | null> | null;
  expertise?: Array<provExpertiseObj | null> | null;
  noOfHonour?: string | null;
  noOfPublication?: string | null;
  providerTraining?: Array<providerTrainingData | null> | null;
  boardCards?: Array<boardCardsData | null> | null;
  disciplinaryAction?: Array<disciplinaryActionData | null> | null;
  ProviderServices?: Array<ProviderServicesData | null> | null;
};

export type gradList = {
  __typename: "gradList";
  gradName?: string | null;
};

export type honors = {
  __typename: "honors";
  honors?: string | null;
};

export type publication = {
  __typename: "publication";
  publications?: string | null;
};

export type provLocCompare = {
  __typename: "provLocCompare";
  CCN?: string | null;
  City?: string | null;
  State?: string | null;
  ZipCode?: string | null;
  Latitude?: number | null;
  Longitude?: number | null;
  GroupPacID?: string | null;
  GroupMembers?: string | null;
  LocationName?: string | null;
  HealthSystemID?: string | null;
  StreetAddress1?: string | null;
  StreetAddress2?: string | null;
  HealthSystemCity?: string | null;
  HealthSystemName?: string | null;
  AcuteHospitalFlag?: string | null;
  HealthSystemState?: string | null;
  LocationAddress?: string | null;
  milesAway?: string | null;
  phone?: string | null;
  primaryTransit?: Array<primaryTransit | null> | null;
};

export type provGroupHospObj = {
  __typename: "provGroupHospObj";
  City?: string | null;
  StreetAddress1?: string | null;
  StreetAddress2?: string | null;
  AcuteHospitalFlag?: string | null;
  Longitude?: number | null;
  ZipCode?: string | null;
  LocationName?: string | null;
  GroupName?: string | null;
  GroupMembers?: string | null;
  GroupPacID?: string | null;
  State?: string | null;
  CCN?: string | null;
  Latitude?: string | null;
  facilitySubType?: string | null;
  SimbiqScore?: string | null;
  MedicalSchoolAffiliation?: string | null;
  facilityType?: string | null;
  caseMixIndex?: string | null;
  LocationAddress?: string | null;
  milesAway?: string | null;
  phone?: string | null;
};

export type provSysHealthObj = {
  __typename: "provSysHealthObj";
  total_mds?: string | null;
  sys_beds?: string | null;
  acutehosp_cnt?: string | null;
  grp_cnt?: string | null;
  prim_care_mds?: string | null;
  hosp_cnt?: string | null;
  sys_multistate?: string | null;
  sys_teachint?: string | null;
  HealthSystemID?: string | null;
  HealthSystemCity?: string | null;
  HealthSystemName?: string | null;
  HealthSystemState?: string | null;
};

export type provExpertiseObj = {
  __typename: "provExpertiseObj";
  ccsAvgAnnualPatientVolume?: string | null;
  ccsProcCodeName?: string | null;
  stateAvgAnnualSvcVolume?: string | null;
  ccsPercentAvgAnnualPatientVolume?: string | null;
  stateAvgAnnualPatientVolume?: string | null;
  ccsPercentAvgAnnualSvcVolume?: string | null;
  ccsProcCode?: string | null;
  ccsAvgAnnualSvcVolume?: string | null;
};

export type providerTrainingData = {
  __typename: "providerTrainingData";
  ProvTrainingFromDate?: string | null;
  ProvTrainingEndDate?: string | null;
  ProvTrainingLocation?: string | null;
  ProvTrainingSpecialty?: string | null;
  ProvTrainingType?: string | null;
};

export type boardCardsData = {
  __typename: "boardCardsData";
  specialty?: string | null;
  certCard?: string | null;
  subspecCard?: string | null;
};

export type ProviderServicesData = {
  __typename: "ProviderServicesData";
  HCPCS_Code?: string | null;
  Description?: string | null;
  Drug?: string | null;
  Number_of_Services?: string | null;
  Place_of_Service?: string | null;
  Number_of_Beneficiaries?: string | null;
  Average_Submitted_Charge?: string | null;
  Average_Medicare_Allowed_Amount?: string | null;
  Average_Medicare_Payment?: string | null;
};

export type ProviderHospitalDetail = {
  __typename: "ProviderHospitalDetail";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<providerHospitalData | null> | null;
};

export type providerHospitalData = {
  __typename: "providerHospitalData";
  ccn?: string | null;
  name?: string | null;
  website?: string | null;
  about?: string | null;
  simbiqScore?: string | null;
  primaryLocation?: ProviderListPrimaryLocation | null;
  phoneNumber?: string | null;
  acutualFlag?: string | null;
  cohort?: string | null;
  hospitalType?: string | null;
  cityState?: string | null;
  primaryLat?: number | null;
  primaryLong?: number | null;
  primaryNearest?: number | null;
  locationList?: Array<ProviderDetailListPrimaryLocation | null> | null;
  locationCount?: string | null;
  topDRGList?: Array<topDrgListObj | null> | null;
  serviceAndDepartments?: Array<facilityavailableServices | null> | null;
  healthSystemDetails?: Array<hospitalHealthSystemData | null> | null;
  providerAffiliationList?: Array<HospitalAffiliationObj | null> | null;
  caseMixIndex?: string | null;
  providerSpecialties?: Array<providerSpecialtyObj | null> | null;
  commercialStateRP?: string | null;
  staffedBeds?: string | null;
  ervVisits?: string | null;
  opVisits?: string | null;
  alos?: string | null;
  ipDischarges?: string | null;
};

export type ProviderDetailListPrimaryLocation = {
  __typename: "ProviderDetailListPrimaryLocation";
  locationAddress?: string | null;
  locationName?: string | null;
  milesAway?: string | null;
  phone?: string | null;
  lat?: number | null;
  lng?: number | null;
  primaryTransit?: Array<primaryTransit | null> | null;
};

export type topDrgListObj = {
  __typename: "topDrgListObj";
  DRGRegionalDischargePct?: string | null;
  DRGDischarges?: string | null;
  DRG?: string | null;
};

export type hospitalHealthSystemData = {
  __typename: "hospitalHealthSystemData";
  city?: string | null;
  state?: string | null;
  name?: string | null;
  totalMds?: string | null;
  sysBeds?: string | null;
  hospCnt?: string | null;
  grpCnt?: string | null;
  primeCareMds?: string | null;
};

export type HospitalAffiliationObj = {
  __typename: "HospitalAffiliationObj";
  provMiddleLast?: string | null;
  provSpecialtyList?: string | null;
  npi?: string | null;
  provNameLast?: string | null;
  provTaxList?: string | null;
  provCredential?: string | null;
};

export type providerSpecialtyObj = {
  __typename: "providerSpecialtyObj";
  TaxonomyCodeCount?: number | null;
  TaxCode?: string | null;
  DisplayName?: string | null;
};

export type ProviderFacilityDetailNew = {
  __typename: "ProviderFacilityDetailNew";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<providerFacilityDataNew | null> | null;
};

export type userLogsObject = {
  userId?: string | null;
  type?: string | null;
  doctorName?: string | null;
  npi?: string | null;
  searchKey?: string | null;
  specialty?: string | null;
  dateTime?: string | null;
  location?: string | null;
  hospitalName?: string | null;
  ccn?: string | null;
  facilityName?: string | null;
  url?: string | null;
  sessionDuration?: string | null;
  tenantName?: string | null;
  userType?: string | null;
  userLocation?: string | null;
  zipCode?: string | null;
  sex?: string | null;
  dateOfBirth?: string | null;
  benefitId?: string | null;
  benefitActionType?: string | null;
  benefitUrl?: string | null;
  benefitName?: string | null;
  benefitRecommandationRating?: string | null;
  userZipcode?: string | null;
  searchZipcode?: string | null;
};

export type addUserTrailLogsResponse = {
  __typename: "addUserTrailLogsResponse";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
};

export type userLogsResponse = {
  __typename: "userLogsResponse";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<userLogsObjectJson | null> | null;
};

export type userLogsObjectJson = {
  __typename: "userLogsObjectJson";
  userId?: string | null;
  type?: string | null;
  doctorName?: string | null;
  npi?: string | null;
  searchKey?: string | null;
  specialty?: string | null;
  specialty1?: string | null;
  specialty2?: string | null;
  specialty3?: string | null;
  specialty4?: string | null;
  specialty5?: string | null;
  dateTime?: string | null;
  location?: string | null;
  hospitalName?: string | null;
  ccn?: string | null;
  facilityName?: string | null;
  url?: string | null;
  sessionDuration?: string | null;
  tenantName?: string | null;
  userType?: string | null;
  userLocation?: string | null;
  zipCode?: string | null;
  sex?: string | null;
  dateOfBirth?: string | null;
  benefitId?: string | null;
  actionType?: string | null;
  benefitUrl?: string | null;
  benefitName?: string | null;
  benefitRecommandationRating?: string | null;
};

export type setUpdateAttribute = {
  name: string;
  value: string;
};

export type userAttributeResponse = {
  __typename: "userAttributeResponse";
  status?: SuccessResponse | null;
  result?: Array<singleattributeResponse | null> | null;
};

export type singleattributeResponse = {
  __typename: "singleattributeResponse";
  Username: string;
  UserAttributes?: Array<attributeKeyPair | null> | null;
};

export type attributeKeyPair = {
  __typename: "attributeKeyPair";
  Name: string;
  Value: string;
};

export type cognitoUserObj = {
  userName?: string | null;
};

export type cognitoUserAttributeResponse = {
  __typename: "cognitoUserAttributeResponse";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<cognitoUsersList | null> | null;
};

export type cognitoUsersList = {
  __typename: "cognitoUsersList";
  firstName?: string | null;
  Username?: string | null;
  Enabled?: boolean | null;
  UserStatus?: string | null;
  lastName?: string | null;
  userType?: string | null;
  lastLoginTime?: string | null;
  employeeID?: string | null;
};

export type filterSpeciaties = {
  __typename: "filterSpeciaties";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<filterData | null> | null;
};

export type filterData = {
  __typename: "filterData";
  providerType?: string | null;
  specialtyList?: Array<filterSpeciatiesData | null> | null;
};

export type filterSpeciatiesData = {
  __typename: "filterSpeciatiesData";
  specialtyName?: string | null;
  taxonomyCode?: string | null;
  subSpecialtyList?: Array<filterSubSpecialties | null> | null;
};

export type filterSubSpecialties = {
  __typename: "filterSubSpecialties";
  subSpecialtyName?: string | null;
  taxList?: string | null;
};

export type sesMailResponse = {
  __typename: "sesMailResponse";
  status?: SuccessResponse | null;
};

export type appVersionConfig = {
  __typename: "appVersionConfig";
  status?: SuccessResponse | null;
  result?: string | null;
};

export type userProviderReview = {
  __typename: "userProviderReview";
  status?: SuccessResponse | null;
  response?: PaginationResponse | null;
  result?: Array<userReview | null> | null;
};

export type PaginationResponse = {
  __typename: "PaginationResponse";
  totalCount?: number | null;
  pageLimit?: number | null;
  page?: number | null;
  avgDataList?: Array<avgData | null> | null;
};

export type avgData = {
  __typename: "avgData";
  avgCommunicatioRating?: string | null;
  avgOutcomeResultsRating?: string | null;
};

export type userReview = {
  __typename: "userReview";
  userId: string;
  npiId: string;
  easeOfCommunicatioRating?: string | null;
  outcomeResultsRating?: string | null;
  writingReview?: string | null;
  username?: string | null;
  updatedDate?: string | null;
};

export type reviewGuidelines = {
  __typename: "reviewGuidelines";
  status?: SuccessResponse | null;
  result?: string | null;
};

export type checkInNetwork = {
  __typename: "checkInNetwork";
  result?: string | null;
};

export type treatmentCenter = {
  __typename: "treatmentCenter";
  result?: treatmentCenterObj | null;
};

export type treatmentCenterObj = {
  __typename: "treatmentCenterObj";
  page?: string | null;
  totalPages?: string | null;
  recordCount?: string | null;
  rows?: Array<RowsData | null> | null;
};

export type RowsData = {
  __typename: "RowsData";
  _irow?: string | null;
  name1?: string | null;
  name2?: string | null;
  street1?: string | null;
  city?: string | null;
  street2?: string | null;
  state?: string | null;
  zip?: string | null;
  phone?: string | null;
  intake1?: string | null;
  hotline1?: string | null;
  website?: string | null;
  latitude?: string | null;
  longitude?: string | null;
  miles?: string | null;
  services?: Array<ServicesData | null> | null;
  typeFacility?: string | null;
};

export type ServicesData = {
  __typename: "ServicesData";
  f1?: string | null;
  f2?: string | null;
  f3?: string | null;
};

export type providerSearch = {
  __typename: "providerSearch";
  status?: SuccessResponse | null;
  result?: string | null;
};

export type getSessionUser = {
  __typename: "getSessionUser";
  status?: SuccessResponse | null;
  result?: Array<getSessionUserResult | null> | null;
};

export type getSessionUserResult = {
  __typename: "getSessionUserResult";
  sub?: string | null;
  code?: string | null;
  state?: string | null;
};

export type objSearchData = {
  data?: Array<arrayData | null> | null;
};

export type metaDataObj = {
  name:String
  npi:String
  type:String
  sex:String
  elderDependence:String
  childDependence:String
  language:String
  specialty:String
}

export type arrayData = {
  id?: string | null;
  pageContent: string;
  metadata?: metaDataObj | null;
};

export type gethyperLocalSearchResp = {
  __typename: "gethyperLocalSearchResp";
  return?: returnObj | null;
};

export type gethyperLocalSearchResult = {
  __typename: "gethyperLocalSearchResult";
  name?: string | null;
  npi?: string | null;
  type?: string | null;
  sex?: string | null;
  elderDependence?: string | null;
  childDependence?: string | null;
  language?: string | null;
  specialty?: string | null;
};

export type returnObj = {
  __typename: "returnObj";
  statusCode?: string | null;
};

export type inviteFamilyMemberInputData = {
  memberFirstName?: string | null;
  memberLastName?: string | null;
  memberPersonalEmail?: string | null;
  subscribeId?: string | null;
  userType?: string | null;
  relationshipType?: string | null;
};

export type InviteFamilyMemberQuery = {
  __typename: "userUpdateProfileSettingResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
};

export type getSdohDomainTopicsData = {
  __typename: "getSdohDomainTopicsData";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<getSdohDomainTopicsDataResponse | null> | null;
};

export type getSdohDomainTopicsDataResponse = {
  __typename: "getSdohDomainTopicsDataResponse";
  domain?: string | null;
  topics?: Array<topicsData | null> | null;
};

export type topicsData = {
  __typename: "topicsData";
  name?: string | null;
};

export type getSdohZctaAHRQDataResponse = {
  __typename: "getSdohZctaAHRQDataResponse";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<getSdohZctaAHRQResult | null> | null;
};

export type getSdohZctaAHRQResult = {
  __typename: "getSdohZctaAHRQResult";
  variableName?: string | null;
  variableLabel?: string | null;
  value?: string | null;
};

export type getAllSdoZhipCodeResponse = {
  __typename: "getAllSdoZhipCodeResponse";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<getAllSdoZhipCodeObj | null> | null;
};

export type getAllSdoZhipCodeObj = {
  __typename: "getAllSdoZhipCodeObj";
  code?: string | null;
};

export type getAccessQualityCareResponse = {
  __typename: "getAccessQualityCareResponse";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<getAccessQualityCareObj | null> | null;
};

export type getAccessQualityCareObj = {
  __typename: "getAccessQualityCareObj";
  ZIPCODE?: string | null;
  STATE?: string | null;
  REGION?: string | null;
  ACS_PCT_MEDICAID_ANY_ZC?: string | null;
  ACS_PCT_MEDICAID_ANY_BELOW64_ZC?: string | null;
  ACS_PCT_MEDICARE_ONLY_ZC?: string | null;
  ACS_PCT_OTHER_INS_ZC?: string | null;
  ACS_PCT_PVT_EMPL_DRCT_ZC?: string | null;
  ACS_PCT_PVT_EMPL_DRCT_BELOW64_ZC?: string | null;
  ACS_PCT_PRIVATE_ANY_ZC?: string | null;
  ACS_PCT_PRIVATE_ANY_BELOW64_ZC?: string | null;
  ACS_PCT_PRIVATE_EMPL_ZC?: string | null;
  ACS_PCT_PRIVATE_EMPL_BELOW64_ZC?: string | null;
  ACS_PCT_PRIVATE_MDCR_ZC?: string | null;
  ACS_PCT_PRIVATE_MDCR_35_64_ZC?: string | null;
  ACS_PCT_PRIVATE_OTHER_ZC?: string | null;
  ACS_PCT_PRIVATE_OTHER_BELOW64_ZC?: string | null;
  ACS_PCT_PRIVATE_SELF_ZC?: string | null;
  ACS_PCT_PRIVATE_SELF_BELOW64_ZC?: string | null;
  ACS_PCT_PUBLIC_ONLY_ZC?: string | null;
  ACS_PCT_PUBLIC_OTHER_ZC?: string | null;
  ACS_PCT_PUBLIC_OTHER_BELOW64_ZC?: string | null;
  ACS_PCT_SELF_MDCR_ABOVE35_ZC?: string | null;
  ACS_PCT_TRICARE_VA_ZC?: string | null;
  ACS_PCT_TRICARE_VA_BELOW64_ZC?: string | null;
  ACS_PCT_UNINSURED_ZC?: string | null;
  ACS_PCT_UNINSURED_BELOW64_ZC?: string | null;
  HIFLD_DIST_UC_ZP?: string | null;
  POS_DIST_ED_ZP?: string | null;
  POS_DIST_MEDSURG_ICU_ZP?: string | null;
  POS_DIST_TRAUMA_ZP?: string | null;
  POS_DIST_PED_ICU_ZP?: string | null;
  POS_DIST_OBSTETRICS_ZP?: string | null;
  POS_DIST_CLINIC_ZP?: string | null;
  POS_DIST_ALC_ZP?: string | null;
  CEN_AIAN_NH_IND?: string | null;
  CCBP_TOT_HOME_ZP?: string | null;
  CDCP_ARTHRITIS_ADULT_C_ZC?: string | null;
  CDCP_ASTHMA_ADULT_C_ZC?: string | null;
  CDCP_BLOOD_MED_ADULT_C_ZC?: string | null;
  CDCP_CHOLES_ADULT_C_ZC?: string | null;
  CDCP_CHOLES_SCR_ADULT_C_ZC?: string | null;
  CDCP_DOCTOR_VISIT_ADULT_C_ZC?: string | null;
  CDCP_KIDNEY_DISEASE_ADULT_C_ZC?: string | null;
  CDCP_NO_PHY_ACTV_ADULT_C_ZC?: string | null;
  CDCP_PULMONARY_ADULT_C_ZC?: string | null;
  CDCP_CERVCAN_SCR_F21_65_C_ZC?: string | null;
  CDCP_DENTIST_VISIT_ADULT_C_ZC?: string | null;
  CDCP_FOBT_SIG_COL_5String_75_C_ZC?: string | null;
  CDCP_MAMMO_SCR_F5String_74_C_ZC?: string | null;
  CDCP_NO_TEETH_65_ABOVE_C_ZC?: string | null;
  CDCP_PREV_SERV_F65_ABOVE_C_ZC?: string | null;
  CDCP_PREV_SERV_M65_ABOVE_C_ZC?: string | null;
  CDCP_SLEEP_LESS7HR_ADULT_C_ZC?: string | null;
};

export type accessQualityDescriptionResponse = {
  __typename: "accessQualityDescriptionResponse";
  status?: SuccessResponse | null;
  response?: PageResponse | null;
  result?: Array<accessQualityDescriptionResult | null> | null;
};

export type accessQualityDescriptionResult = {
  __typename: "accessQualityDescriptionResult";
  variableName?: string | null;
  variableLabel?: string | null;
};

export type GetCondtionTabDataQuery = {
  __typename: "procedureTab";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "ProcedureTabData";
    taxList?: string | null;
    displayName?: string | null;
    description?: string | null;
  } | null> | null;
};

export type GetProcedureTabDataQuery = {
  __typename: "procedureTab";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "ProcedureTabData";
    taxList?: string | null;
    displayName?: string | null;
    description?: string | null;
  } | null> | null;
};

export type GetSearchPhraseSpecialtiesDataQuery = {
  __typename: "SearchPhrase";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "SearchPhraseData";
    DisplayName?: string | null;
    PhraseType?: string | null;
    ProviderGroup?: string | null;
    SearchType?: number | null;
    TypeID?: number | null;
    TaxList?: string | null;
    Npi?: string | null;
    Ccn?: string | null;
  } | null> | null;
};

export type GetOsSearchPhraseSpecialtiesDataQuery = {
  __typename: "oSSearchPhrase";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: string | null;
};

export type GetOsFacilitiesDataQuery = {
  __typename: "NpiHospitalInfoByPcTc";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: {
    __typename: "NpiHospitalInfoByPcTcDataObj";
    filterData?: Array<{
      __typename: "NpiHospitalInfoByPcTcData";
      ccn?: string | null;
      facilityID?: string | null;
      facilityName?: string | null;
      facilityType?: string | null;
      phone?: string | null;
      clia?: string | null;
      hospitalName?: string | null;
      medicalSchoolAffiliation?: string | null;
      caseMixIndex?: string | null;
      hospitalType?: string | null;
      simbiqScore?: string | null;
      primaryNearest?: string | null;
      cityState?: string | null;
      primaryLocation?: {
        __typename: "ProviderListPrimaryLocation";
        locationAddress?: string | null;
        locationName?: string | null;
        milesAway?: string | null;
        phone?: string | null;
        lat?: number | null;
        lng?: number | null;
      } | null;
      primaryTransit?: Array<{
        __typename: "primaryTransit";
        stop_name?: string | null;
        stop_desc?: string | null;
        stop_lat?: string | null;
        stop_lon?: string | null;
        stop_url?: string | null;
        wheelchair_boarding?: string | null;
        vehicle_type?: string | null;
        stop_addres?: string | null;
        milesAway?: string | null;
      } | null> | null;
      centerPoint?: string | null;
      provType?: string | null;
    } | null> | null;
    findatDistanceKm?: string | null;
    hospitalSysFilter?: Array<{
      __typename: "hospitalAffFilterobj";
      name: string;
    } | null> | null;
    hospitalFacilityFilter?: Array<{
      __typename: "hospitalAffFilterobj";
      name: string;
    } | null> | null;
    facilityTypeFilter?: Array<{
      __typename: "hospitalAffFilterobj";
      name: string;
    } | null> | null;
  } | null;
};

export type GetEmbededUrlQuery = {
  __typename: "getResponseEmbededUrl";
  statusCode?: number | null;
  body?: string | null;
};

export type GetStandardChargeQuery = {
  __typename: "standardCharge";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "standardCharge";
    status?: {
      __typename: "SuccessResponse";
      statusCode: number;
      msg: string;
    } | null;
    response?: {
      __typename: "PageResponse";
      totalCount: number;
      pageLimit: number;
      page: number;
      offset: number;
      totalRecordView: number;
    } | null;
    result?: Array<{
      __typename: "standardCharge";
      status?: {
        __typename: "SuccessResponse";
        statusCode: number;
        msg: string;
      } | null;
      response?: {
        __typename: "PageResponse";
        totalCount: number;
        pageLimit: number;
        page: number;
        offset: number;
        totalRecordView: number;
      } | null;
      result?: Array<{
        __typename: "standardCharge";
        status?: {
          __typename: "SuccessResponse";
          statusCode: number;
          msg: string;
        } | null;
        response?: {
          __typename: "PageResponse";
          totalCount: number;
          pageLimit: number;
          page: number;
          offset: number;
          totalRecordView: number;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type GetProcedureServiceCodeQuery = {
  __typename: "standardCharge";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "standardChargeResponse";
    code?: string | null;
    codeDesc?: string | null;
    codeType?: string | null;
  } | null> | null;
};

export type GetInNetworkDetailsQuery = {
  __typename: "inStatndardInNetwork";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "inStatndardInNetworkData";
    payerName?: string | null;
    plans?: Array<plansObj | null> | null;
  } | null> | null;
};

export type GetStandardChargeDataQuery = {
  __typename: "getStandardChargeData";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "getStandardChargeDataResponse";
    svcCodeDescription?: string | null;
    code_1?: string | null;
    code_1_type?: string | null;
    code_2?: string | null;
    code_2_type?: string | null;
    code_3?: string | null;
    code_3_type?: string | null;
    code_4?: string | null;
    code_4_type?: string | null;
    modifierCode?: string | null;
    setting?: string | null;
    drug_unit_of_measurement?: string | null;
    drug_type_of_measurement?: string | null;
    standardGrossCharge?: string | null;
    standard_charge_discounted_cash?: string | null;
    payerName?: string | null;
    planName?: string | null;
    standard_charge_negotiated_dollar?: string | null;
    standard_charge_negotiated_percentage?: string | null;
    standard_charge_negotiated_algorithm?: string | null;
    estimated_amount?: string | null;
    standard_charge_methodology?: string | null;
    minCharge?: string | null;
    maxCharge?: string | null;
    additional_generic_notes?: string | null;
    hospital_name?: string | null;
    hospital_address?: string | null;
    facilityCCN?: string | null;
    facilityID?: string | null;
    ccnResultsFacility?: Array<ccnResultsFacilityObj | null> | null;
  } | null> | null;
};

export type GetSearchKeyTaxListDataQuery = {
  __typename: "SearchPhrase";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "SearchPhraseData";
    DisplayName?: string | null;
    PhraseType?: string | null;
    ProviderGroup?: string | null;
    SearchType?: number | null;
    TypeID?: number | null;
    TaxList?: string | null;
    Npi?: string | null;
    Ccn?: string | null;
  } | null> | null;
};

export type GetParameterDataByNameQuery = {
  __typename: "parameterPass";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  result?: Array<{
    __typename: "parameterPassData";
    Name: string;
    Type: string;
    Value: string;
  } | null> | null;
};

export type GetTaxListByShortcutNameQuery = {
  __typename: "taxListsByShortcut";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "taxLists";
    taxList: string;
  } | null> | null;
};

export type GetUserSettingsQuery = {
  __typename: "userSettingResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "settingResponse";
    DefaultLanguage: string;
    TextNewResultSavedSearch: number;
    TextFavoriteAcceptingNew: number;
    TextAppointmentReminder: number;
    EmailNewResultSavedSearch: number;
    EmailFavoriteAcceptingNew: number;
    EmailAppointmentReminder: number;
  } | null> | null;
};

export type GetSdohSourceQuery = {
  __typename: "getdomainSourceResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "domainUserSource";
    userSdohInfo?: Array<{
      __typename: "userSdohInfoData";
      subId?: string | null;
      userPoolId?: string | null;
      aboutSdoh?: Array<{
        __typename: "aboutSdohData";
        domainName?: string | null;
        status?: number | null;
        correctAnswers?: Array<{
          __typename: "correctAnswersdata";
          questionCode?: string | null;
          answerCode?: string | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
    sourceSdoh?: Array<{
      __typename: "sourceSdohData";
      domainName?: string | null;
      questionAnswerInfo?: Array<{
        __typename: "questionAnswerInfoSource";
        questionCode?: string | null;
        question?: string | null;
        answerType?: string | null;
        answers?: Array<{
          __typename: "answersData";
          answerCode?: string | null;
          answer?: string | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type GetTranslateTextQuery = {
  __typename: "getTranslateTextResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  result?: {
    __typename: "translateTextObj";
    TranslatedText?: string | null;
    SourceLanguageCode?: string | null;
    TargetLanguageCode?: string | null;
  } | null;
};

export type GetDomainsQuery = {
  __typename: "getdomainStatusResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "domainNames";
    status?: number | null;
    domainName?: string | null;
  } | null> | null;
};

export type AddUpdateSdohQuery = {
  __typename: "userUpdateProfileSettingResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
};

export type GetRecommendationsQuery = {
  __typename: "userBenefitRecommendationResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "benefitRecommedationData";
    benefits?: Array<{
      __typename: "benefitObjData";
      benefitID?: number | null;
      benefitName?: string | null;
      benefitLogo?: string | null;
      benefitType?: string | null;
      benefitSummary?: string | null;
      benefitMemberID?: string | null;
      benefitSubscriberID?: string | null;
      benefitCost?: string | null;
      benefitGroupID?: string | null;
      benefitWebsite?: string | null;
      benefitWebsiteDisplayName?: string | null;
      benefitPhone?: string | null;
      benefitLoginInstructions?: string | null;
    } | null> | null;
  } | null> | null;
};

export type AddDismissQuery = {
  __typename: "userUpdateProfileSettingResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
};

export type UpdateProfileSettingsQuery = {
  __typename: "userUpdateProfileSettingResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
};

export type GetProfileSettingsQuery = {
  __typename: "userProfileGetResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "userProfileGetData";
    clientId?: string | null;
    memberId?: string | null;
    communication?: {
      __typename: "communicationPreferenceData";
      email?: number | null;
      text?: number | null;
    } | null;
    location?: string | null;
    poolId?: string | null;
    subId?: string | null;
    subscribeId?: string | null;
    tenantId?: string | null;
    clientName?: string | null;
    memberAccessCode?: string | null;
    memberFullName?: string | null;
    memberSsnCode?: string | null;
    memberCity?: string | null;
    memberDOB?: string | null;
    memberEmailConsent?: string | null;
    memberPersonalEmail?: string | null;
    memberFirstName?: string | null;
    memberGenderIdentity?: string | null;
    memberHireDate?: string | null;
    memberLastName?: string | null;
    memberPhone?: string | null;
    memberPhoneType?: string | null;
    memberPreferredLanguage?: string | null;
    memberPreferredName?: string | null;
    memberSex?: string | null;
    memberStreetAddress1?: string | null;
    memberStreetAddress2?: string | null;
    memberTermDate?: string | null;
    memberTextConsent?: string | null;
    memberWorkEmail?: string | null;
    memberZipCode?: string | null;
    simbiqAccessCode?: string | null;
    subscriberFlag?: string | null;
    subscriberPlanType?: string | null;
    race?: string | null;
    ethnicity?: string | null;
    childDependentFlag?: string | null;
    elderDependentFlag?: string | null;
    userBenefits?: Array<{
      __typename: "benefitObjData";
      benefitID?: number | null;
      benefitName?: string | null;
      benefitLogo?: string | null;
      benefitType?: string | null;
      benefitSummary?: string | null;
      benefitMemberID?: string | null;
      benefitSubscriberID?: string | null;
      benefitCost?: string | null;
      benefitGroupID?: string | null;
      benefitWebsite?: string | null;
      benefitWebsiteDisplayName?: string | null;
      benefitPhone?: string | null;
      benefitLoginInstructions?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetTenantConfigQuery = {
  __typename: "userTenantConfigResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "elementsObjData";
    primaryFont?: string | null;
    pageTitle?: string | null;
    subTitle?: string | null;
    topBackground?: string | null;
    placeHolder?: string | null;
    textBlack?: string | null;
    iconHover?: string | null;
    borderGray?: string | null;
    cardBorder?: string | null;
    cardHover?: string | null;
    iconColor?: string | null;
    linkHover?: string | null;
    buttonHover?: string | null;
    filterChip?: string | null;
    filterchipBorder?: string | null;
    filterchipActive?: string | null;
    fchipactiveBorder?: string | null;
  } | null> | null;
};

export type UpdateTenantTemplateQuery = {
  __typename: "userUpdateResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
};

export type DeleteTenantTemplateQuery = {
  __typename: "userUpdateResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
};

export type GetUserBenefitsQuery = {
  __typename: "userBenefitResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "benefitData";
    benefits?: Array<{
      __typename: "benefitObjData";
      benefitID?: number | null;
      benefitName?: string | null;
      benefitLogo?: string | null;
      benefitType?: string | null;
      benefitSummary?: string | null;
      benefitMemberID?: string | null;
      benefitSubscriberID?: string | null;
      benefitCost?: string | null;
      benefitGroupID?: string | null;
      benefitWebsite?: string | null;
      benefitWebsiteDisplayName?: string | null;
      benefitPhone?: string | null;
      benefitLoginInstructions?: string | null;
    } | null> | null;
    clientData?: {
      __typename: "userPoolClientData";
      deptName?: string | null;
      tenantName?: string | null;
      availableHrs?: string | null;
      phone?: string | null;
      phoneInternal?: string | null;
      email?: string | null;
    } | null;
  } | null> | null;
};

export type DeleteUserBenefitsQuery = {
  __typename: "userUpdateResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
};

export type AddUserBenefitsQuery = {
  __typename: "userUpdateResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
};

export type GetUserFavouritesQuery = {
  __typename: "userFavourites";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: {
    __typename: "favProviderData";
    providers?: Array<{
      __typename: "NpiInfoByPcTcData";
      ProviderNPI?: string | null;
      entityCode?: number | null;
      licenseState?: string | null;
      providerName?: string | null;
      medicalGraduationDate?: string | null;
      provYearsPracticing?: number | null;
      simbiqScore?: string | null;
      gender?: string | null;
      nearestKm?: string | null;
      nearestLocation?: string | null;
      primarySpecialty?: string | null;
      provOrgname?: string | null;
      acceptingNewPatients?: string | null;
      translationService?: string | null;
      faciliyAddress?: string | null;
      mipsScore?: number | null;
      placeHolderText?: string | null;
      primaryLocation?: {
        __typename: "ProviderListPrimaryLocation";
        locationAddress?: string | null;
        locationName?: string | null;
        milesAway?: string | null;
        phone?: string | null;
        lat?: number | null;
        lng?: number | null;
      } | null;
      primaryTransit?: Array<{
        __typename: "primaryTransit";
        stop_name?: string | null;
        stop_desc?: string | null;
        stop_lat?: string | null;
        stop_lon?: string | null;
        stop_url?: string | null;
        wheelchair_boarding?: string | null;
        vehicle_type?: string | null;
        stop_addres?: string | null;
        milesAway?: string | null;
      } | null> | null;
      practiseResult?: Array<{
        __typename: "practiceData";
        locationName: string;
        locationAddress?: string | null;
        milesAway?: number | null;
        lat?: string | null;
        lng?: string | null;
        locationType: string;
      } | null> | null;
      otherLocationCount?: string | null;
      centerPoint?: string | null;
      boardCardsName?: string | null;
      disciplinaryAction?: Array<{
        __typename: "disciplinaryActionData";
        provBoardAction?: string | null;
        provBoardActionDate?: string | null;
        provLicenseState?: string | null;
      } | null> | null;
      provType?: string | null;
    } | null> | null;
    facilities?: Array<{
      __typename: "providerFacilityDataNew";
      ccn?: string | null;
      facilityID?: string | null;
      facilityName?: string | null;
      facilityType?: string | null;
      facilitySummary?: string | null;
      costEstimatorLink?: string | null;
      standardChargeLink?: string | null;
      primaryLocation?: {
        __typename: "ProviderListPrimaryLocation";
        locationAddress?: string | null;
        locationName?: string | null;
        milesAway?: string | null;
        phone?: string | null;
        lat?: number | null;
        lng?: number | null;
      } | null;
      phone?: string | null;
      affiliatedProviders?: Array<{
        __typename: "affiliatedProviders";
        locationAddress?: string | null;
        providerName?: string | null;
      } | null> | null;
      services?: Array<{
        __typename: "facilityavailableServices";
        name?: string | null;
      } | null> | null;
      departments?: Array<{
        __typename: "facilityavailableServices";
        name?: string | null;
      } | null> | null;
      drgList?: Array<{
        __typename: "drgList";
        DRGNo?: number | null;
        DRGName?: string | null;
        DRGDischarges?: string | null;
        DRGRegionalDischargePct?: string | null;
      } | null> | null;
      primaryTransit?: Array<{
        __typename: "primaryTransit";
        stop_name?: string | null;
        stop_desc?: string | null;
        stop_lat?: string | null;
        stop_lon?: string | null;
        stop_url?: string | null;
        wheelchair_boarding?: string | null;
        vehicle_type?: string | null;
        stop_addres?: string | null;
        milesAway?: string | null;
      } | null> | null;
      measurements?: Array<{
        __typename: "measurements";
        measureCode?: string | null;
        measureName?: string | null;
        score?: string | null;
        measureDateRange?: string | null;
      } | null> | null;
      hospitalRatingCount?: {
        __typename: "hospitalRatingCountObj";
        hospitalOwnership?: string | null;
        emergencyServices?: string | null;
        hospitalOverallRating?: string | null;
        safetyGroupMeasureCount?: string | null;
        readmGroupMeasureCount?: string | null;
        ptExpGroupMeasureCount?: string | null;
        teGroupMeasureCount?: string | null;
      } | null;
      type?: string | null;
    } | null> | null;
  } | null;
};

export type GetSavedSearchQuery = {
  __typename: "userSearches";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "favSavedSearchData";
    keyword?: string | null;
    filters?: {
      __typename: "filterOptions";
      distance?: string | null;
      gender?: string | null;
      simbiqScore?: string | null;
      acceptingNewPatients?: string | null;
    } | null;
    taxList?: string | null;
    date?: string | null;
  } | null> | null;
};

export type UpdateSettingsQuery = {
  __typename: "userUpdateResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
};

export type GetNpiInfoDataQuery = {
  __typename: "NpiInfoByPcTc";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: {
    __typename: "NpiInfoByPcTcDataArray";
    filterData?: Array<{
      __typename: "NpiInfoByPcTcData";
      ProviderNPI?: string | null;
      entityCode?: number | null;
      licenseState?: string | null;
      providerName?: string | null;
      medicalGraduationDate?: string | null;
      provYearsPracticing?: number | null;
      simbiqScore?: string | null;
      gender?: string | null;
      nearestKm?: string | null;
      nearestLocation?: string | null;
      primarySpecialty?: string | null;
      provOrgname?: string | null;
      acceptingNewPatients?: string | null;
      translationService?: string | null;
      faciliyAddress?: string | null;
      mipsScore?: number | null;
      placeHolderText?: string | null;
      primaryLocation?: {
        __typename: "ProviderListPrimaryLocation";
        locationAddress?: string | null;
        locationName?: string | null;
        milesAway?: string | null;
        phone?: string | null;
        lat?: number | null;
        lng?: number | null;
      } | null;
      primaryTransit?: Array<{
        __typename: "primaryTransit";
        stop_name?: string | null;
        stop_desc?: string | null;
        stop_lat?: string | null;
        stop_lon?: string | null;
        stop_url?: string | null;
        wheelchair_boarding?: string | null;
        vehicle_type?: string | null;
        stop_addres?: string | null;
        milesAway?: string | null;
      } | null> | null;
      practiseResult?: Array<{
        __typename: "practiceData";
        locationName: string;
        locationAddress?: string | null;
        milesAway?: number | null;
        lat?: string | null;
        lng?: string | null;
        locationType: string;
      } | null> | null;
      otherLocationCount?: string | null;
      centerPoint?: string | null;
      boardCardsName?: string | null;
      disciplinaryAction?: Array<{
        __typename: "disciplinaryActionData";
        provBoardAction?: string | null;
        provBoardActionDate?: string | null;
        provLicenseState?: string | null;
      } | null> | null;
      provType?: string | null;
    } | null> | null;
    findatDistanceKm?: string | null;
    hospitalAffFilter?: Array<{
      __typename: "hospitalAffFilterobj";
      name: string;
    } | null> | null;
    hospitalSysFilter?: Array<{
      __typename: "hospitalAffFilterobj";
      name: string;
    } | null> | null;
    specialtyFilter?: Array<{
      __typename: "specialtyFilterObj";
      name?: string | null;
      taxCode?: string | null;
      type?: string | null;
    } | null> | null;
    hospitalGroupFilter?: Array<{
      __typename: "hospitalAffFilterobj";
      name: string;
    } | null> | null;
    boardCertFilter?: Array<{
      __typename: "hospitalAffFilterobj";
      name: string;
    } | null> | null;
  } | null;
};

export type GetNpiHospitalInfoDataQuery = {
  __typename: "NpiHospitalInfoByPcTc";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: {
    __typename: "NpiHospitalInfoByPcTcDataObj";
    filterData?: Array<{
      __typename: "NpiHospitalInfoByPcTcData";
      ccn?: string | null;
      facilityID?: string | null;
      facilityName?: string | null;
      facilityType?: string | null;
      phone?: string | null;
      clia?: string | null;
      hospitalName?: string | null;
      medicalSchoolAffiliation?: string | null;
      caseMixIndex?: string | null;
      hospitalType?: string | null;
      simbiqScore?: string | null;
      primaryNearest?: string | null;
      cityState?: string | null;
      primaryLocation?: {
        __typename: "ProviderListPrimaryLocation";
        locationAddress?: string | null;
        locationName?: string | null;
        milesAway?: string | null;
        phone?: string | null;
        lat?: number | null;
        lng?: number | null;
      } | null;
      primaryTransit?: Array<{
        __typename: "primaryTransit";
        stop_name?: string | null;
        stop_desc?: string | null;
        stop_lat?: string | null;
        stop_lon?: string | null;
        stop_url?: string | null;
        wheelchair_boarding?: string | null;
        vehicle_type?: string | null;
        stop_addres?: string | null;
        milesAway?: string | null;
      } | null> | null;
      centerPoint?: string | null;
      provType?: string | null;
    } | null> | null;
    findatDistanceKm?: string | null;
    hospitalSysFilter?: Array<{
      __typename: "hospitalAffFilterobj";
      name: string;
    } | null> | null;
    hospitalFacilityFilter?: Array<{
      __typename: "hospitalAffFilterobj";
      name: string;
    } | null> | null;
    facilityTypeFilter?: Array<{
      __typename: "hospitalAffFilterobj";
      name: string;
    } | null> | null;
  } | null;
};

export type GetSpecialityTabDataQuery = {
  __typename: "SpecialityTab";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "SpecialityTabData";
    specialtycode?: string | null;
    specialtyname?: string | null;
    description?: string | null;
    subSpecresult?: Array<{
      __typename: "subSpecData";
      subspecialtycode: string;
      subspecialtyname: string;
      description: string;
    } | null> | null;
  } | null> | null;
};

export type GetUserAttemptReportQuery = {
  __typename: "userUpdateResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
};

export type GetCityStateDataQuery = {
  __typename: "cityStateInfo";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  result?: {
    __typename: "cityStateData";
    zipcode: string;
  } | null;
};

export type GetProviderDetailDataQuery = {
  __typename: "ProviderDetail";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "providerData";
    npi: string;
    entityCode: string;
    licenseState?: string | null;
    year?: string | null;
    phoneNumber?: string | null;
    primaryZipCode?: string | null;
    primaryTopLocation?: string | null;
    primaryMilesAway?: number | null;
    medicalGraduationDate?: string | null;
    provYearsPracticing?: number | null;
    translationService?: string | null;
    licenseNumber?: string | null;
    providerName: string;
    proFirst: string;
    proMiddle: string;
    proLast: string;
    provName: string;
    provGender: string;
    medicalGraduation?: string | null;
    provTaxList: string;
    medicalGraduationSchool?: string | null;
    acceptingNewPatients?: string | null;
    provPostGradList?: Array<{
      __typename: "gradList";
      gradName?: string | null;
    } | null> | null;
    provHonors?: Array<{
      __typename: "honors";
      honors?: string | null;
    } | null> | null;
    provPublications?: Array<{
      __typename: "publication";
      publications?: string | null;
    } | null> | null;
    provOrgName: string;
    provGroupSpecialtyType?: string | null;
    provGroupName?: string | null;
    primarySpecialty?: string | null;
    simbiqScore?: number | null;
    provInsuranceAccepted?: string | null;
    provAcceptingMedicaid?: string | null;
    provMedicareParticipating?: string | null;
    provLocationList?: Array<{
      __typename: "provLocCompare";
      CCN?: string | null;
      City?: string | null;
      State?: string | null;
      ZipCode?: string | null;
      Latitude?: number | null;
      Longitude?: number | null;
      GroupPacID?: string | null;
      GroupMembers?: string | null;
      LocationName?: string | null;
      HealthSystemID?: string | null;
      StreetAddress1?: string | null;
      StreetAddress2?: string | null;
      HealthSystemCity?: string | null;
      HealthSystemName?: string | null;
      AcuteHospitalFlag?: string | null;
      HealthSystemState?: string | null;
      LocationAddress?: string | null;
      milesAway?: string | null;
      phone?: string | null;
      primaryTransit?: Array<{
        __typename: "primaryTransit";
        stop_name?: string | null;
        stop_desc?: string | null;
        stop_lat?: string | null;
        stop_lon?: string | null;
        stop_url?: string | null;
        wheelchair_boarding?: string | null;
        vehicle_type?: string | null;
        stop_addres?: string | null;
        milesAway?: string | null;
      } | null> | null;
    } | null> | null;
    provGroupAffiliationList?: Array<{
      __typename: "provGroupHospObj";
      City?: string | null;
      StreetAddress1?: string | null;
      StreetAddress2?: string | null;
      AcuteHospitalFlag?: string | null;
      Longitude?: number | null;
      ZipCode?: string | null;
      LocationName?: string | null;
      GroupName?: string | null;
      GroupMembers?: string | null;
      GroupPacID?: string | null;
      State?: string | null;
      CCN?: string | null;
      Latitude?: string | null;
      facilitySubType?: string | null;
      SimbiqScore?: string | null;
      MedicalSchoolAffiliation?: string | null;
      facilityType?: string | null;
      caseMixIndex?: string | null;
      LocationAddress?: string | null;
      milesAway?: string | null;
      phone?: string | null;
    } | null> | null;
    provHospitalAffiliationList?: Array<{
      __typename: "provGroupHospObj";
      City?: string | null;
      StreetAddress1?: string | null;
      StreetAddress2?: string | null;
      AcuteHospitalFlag?: string | null;
      Longitude?: number | null;
      ZipCode?: string | null;
      LocationName?: string | null;
      GroupName?: string | null;
      GroupMembers?: string | null;
      GroupPacID?: string | null;
      State?: string | null;
      CCN?: string | null;
      Latitude?: string | null;
      facilitySubType?: string | null;
      SimbiqScore?: string | null;
      MedicalSchoolAffiliation?: string | null;
      facilityType?: string | null;
      caseMixIndex?: string | null;
      LocationAddress?: string | null;
      milesAway?: string | null;
      phone?: string | null;
    } | null> | null;
    provSystemAffiliationList?: Array<{
      __typename: "provSysHealthObj";
      total_mds?: string | null;
      sys_beds?: string | null;
      acutehosp_cnt?: string | null;
      grp_cnt?: string | null;
      prim_care_mds?: string | null;
      hosp_cnt?: string | null;
      sys_multistate?: string | null;
      sys_teachint?: string | null;
      HealthSystemID?: string | null;
      HealthSystemCity?: string | null;
      HealthSystemName?: string | null;
      HealthSystemState?: string | null;
    } | null> | null;
    expertise?: Array<{
      __typename: "provExpertiseObj";
      ccsAvgAnnualPatientVolume?: string | null;
      ccsProcCodeName?: string | null;
      stateAvgAnnualSvcVolume?: string | null;
      ccsPercentAvgAnnualPatientVolume?: string | null;
      stateAvgAnnualPatientVolume?: string | null;
      ccsPercentAvgAnnualSvcVolume?: string | null;
      ccsProcCode?: string | null;
      ccsAvgAnnualSvcVolume?: string | null;
    } | null> | null;
    noOfHonour?: string | null;
    noOfPublication?: string | null;
    providerTraining?: Array<{
      __typename: "providerTrainingData";
      ProvTrainingFromDate?: string | null;
      ProvTrainingEndDate?: string | null;
      ProvTrainingLocation?: string | null;
      ProvTrainingSpecialty?: string | null;
      ProvTrainingType?: string | null;
    } | null> | null;
    boardCards?: Array<{
      __typename: "boardCardsData";
      specialty?: string | null;
      certCard?: string | null;
      subspecCard?: string | null;
    } | null> | null;
    disciplinaryAction?: Array<{
      __typename: "disciplinaryActionData";
      provBoardAction?: string | null;
      provBoardActionDate?: string | null;
      provLicenseState?: string | null;
    } | null> | null;
    ProviderServices?: Array<{
      __typename: "ProviderServicesData";
      HCPCS_Code?: string | null;
      Description?: string | null;
      Drug?: string | null;
      Number_of_Services?: string | null;
      Place_of_Service?: string | null;
      Number_of_Beneficiaries?: string | null;
      Average_Submitted_Charge?: string | null;
      Average_Medicare_Allowed_Amount?: string | null;
      Average_Medicare_Payment?: string | null;
    } | null> | null;
  } | null> | null;
};

export type GetProviderHospitalDetailDataQuery = {
  __typename: "ProviderHospitalDetail";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "providerHospitalData";
    ccn?: string | null;
    name?: string | null;
    website?: string | null;
    about?: string | null;
    simbiqScore?: string | null;
    primaryLocation?: {
      __typename: "ProviderListPrimaryLocation";
      locationAddress?: string | null;
      locationName?: string | null;
      milesAway?: string | null;
      phone?: string | null;
      lat?: number | null;
      lng?: number | null;
    } | null;
    phoneNumber?: string | null;
    acutualFlag?: string | null;
    cohort?: string | null;
    hospitalType?: string | null;
    cityState?: string | null;
    primaryLat?: number | null;
    primaryLong?: number | null;
    primaryNearest?: number | null;
    locationList?: Array<{
      __typename: "ProviderDetailListPrimaryLocation";
      locationAddress?: string | null;
      locationName?: string | null;
      milesAway?: string | null;
      phone?: string | null;
      lat?: number | null;
      lng?: number | null;
      primaryTransit?: Array<{
        __typename: "primaryTransit";
        stop_name?: string | null;
        stop_desc?: string | null;
        stop_lat?: string | null;
        stop_lon?: string | null;
        stop_url?: string | null;
        wheelchair_boarding?: string | null;
        vehicle_type?: string | null;
        stop_addres?: string | null;
        milesAway?: string | null;
      } | null> | null;
    } | null> | null;
    locationCount?: string | null;
    topDRGList?: Array<{
      __typename: "topDrgListObj";
      DRGRegionalDischargePct?: string | null;
      DRGDischarges?: string | null;
      DRG?: string | null;
    } | null> | null;
    serviceAndDepartments?: Array<{
      __typename: "facilityavailableServices";
      name?: string | null;
    } | null> | null;
    healthSystemDetails?: Array<{
      __typename: "hospitalHealthSystemData";
      city?: string | null;
      state?: string | null;
      name?: string | null;
      totalMds?: string | null;
      sysBeds?: string | null;
      hospCnt?: string | null;
      grpCnt?: string | null;
      primeCareMds?: string | null;
    } | null> | null;
    providerAffiliationList?: Array<{
      __typename: "HospitalAffiliationObj";
      provMiddleLast?: string | null;
      provSpecialtyList?: string | null;
      npi?: string | null;
      provNameLast?: string | null;
      provTaxList?: string | null;
      provCredential?: string | null;
    } | null> | null;
    caseMixIndex?: string | null;
    providerSpecialties?: Array<{
      __typename: "providerSpecialtyObj";
      TaxonomyCodeCount?: number | null;
      TaxCode?: string | null;
      DisplayName?: string | null;
    } | null> | null;
    commercialStateRP?: string | null;
    staffedBeds?: string | null;
    ervVisits?: string | null;
    opVisits?: string | null;
    alos?: string | null;
    ipDischarges?: string | null;
  } | null> | null;
};

export type GetFacilityDetailDataQuery = {
  __typename: "ProviderFacilityDetailNew";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "providerFacilityDataNew";
    ccn?: string | null;
    facilityID?: string | null;
    facilityName?: string | null;
    facilityType?: string | null;
    facilitySummary?: string | null;
    costEstimatorLink?: string | null;
    standardChargeLink?: string | null;
    primaryLocation?: {
      __typename: "ProviderListPrimaryLocation";
      locationAddress?: string | null;
      locationName?: string | null;
      milesAway?: string | null;
      phone?: string | null;
      lat?: number | null;
      lng?: number | null;
    } | null;
    phone?: string | null;
    affiliatedProviders?: Array<{
      __typename: "affiliatedProviders";
      locationAddress?: string | null;
      providerName?: string | null;
    } | null> | null;
    services?: Array<{
      __typename: "facilityavailableServices";
      name?: string | null;
    } | null> | null;
    departments?: Array<{
      __typename: "facilityavailableServices";
      name?: string | null;
    } | null> | null;
    drgList?: Array<{
      __typename: "drgList";
      DRGNo?: number | null;
      DRGName?: string | null;
      DRGDischarges?: string | null;
      DRGRegionalDischargePct?: string | null;
    } | null> | null;
    primaryTransit?: Array<{
      __typename: "primaryTransit";
      stop_name?: string | null;
      stop_desc?: string | null;
      stop_lat?: string | null;
      stop_lon?: string | null;
      stop_url?: string | null;
      wheelchair_boarding?: string | null;
      vehicle_type?: string | null;
      stop_addres?: string | null;
      milesAway?: string | null;
    } | null> | null;
    measurements?: Array<{
      __typename: "measurements";
      measureCode?: string | null;
      measureName?: string | null;
      score?: string | null;
      measureDateRange?: string | null;
    } | null> | null;
    hospitalRatingCount?: {
      __typename: "hospitalRatingCountObj";
      hospitalOwnership?: string | null;
      emergencyServices?: string | null;
      hospitalOverallRating?: string | null;
      safetyGroupMeasureCount?: string | null;
      readmGroupMeasureCount?: string | null;
      ptExpGroupMeasureCount?: string | null;
      teGroupMeasureCount?: string | null;
    } | null;
  } | null> | null;
};

export type GetFacilityDetailDataNewQuery = {
  __typename: "ProviderFacilityDetailNew";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "providerFacilityDataNew";
    ccn?: string | null;
    facilityID?: string | null;
    facilityName?: string | null;
    facilityType?: string | null;
    facilitySummary?: string | null;
    costEstimatorLink?: string | null;
    standardChargeLink?: string | null;
    primaryLocation?: {
      __typename: "ProviderListPrimaryLocation";
      locationAddress?: string | null;
      locationName?: string | null;
      milesAway?: string | null;
      phone?: string | null;
      lat?: number | null;
      lng?: number | null;
    } | null;
    phone?: string | null;
    affiliatedProviders?: Array<{
      __typename: "affiliatedProviders";
      locationAddress?: string | null;
      providerName?: string | null;
    } | null> | null;
    services?: Array<{
      __typename: "facilityavailableServices";
      name?: string | null;
    } | null> | null;
    departments?: Array<{
      __typename: "facilityavailableServices";
      name?: string | null;
    } | null> | null;
    drgList?: Array<{
      __typename: "drgList";
      DRGNo?: number | null;
      DRGName?: string | null;
      DRGDischarges?: string | null;
      DRGRegionalDischargePct?: string | null;
    } | null> | null;
    primaryTransit?: Array<{
      __typename: "primaryTransit";
      stop_name?: string | null;
      stop_desc?: string | null;
      stop_lat?: string | null;
      stop_lon?: string | null;
      stop_url?: string | null;
      wheelchair_boarding?: string | null;
      vehicle_type?: string | null;
      stop_addres?: string | null;
      milesAway?: string | null;
    } | null> | null;
    measurements?: Array<{
      __typename: "measurements";
      measureCode?: string | null;
      measureName?: string | null;
      score?: string | null;
      measureDateRange?: string | null;
    } | null> | null;
    hospitalRatingCount?: {
      __typename: "hospitalRatingCountObj";
      hospitalOwnership?: string | null;
      emergencyServices?: string | null;
      hospitalOverallRating?: string | null;
      safetyGroupMeasureCount?: string | null;
      readmGroupMeasureCount?: string | null;
      ptExpGroupMeasureCount?: string | null;
      teGroupMeasureCount?: string | null;
    } | null;
  } | null> | null;
};

export type UpdateUserTrailLogsQuery = {
  __typename: "addUserTrailLogsResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
};

export type GetUserTrailLogsQuery = {
  __typename: "userLogsResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "userLogsObjectJson";
    userId?: string | null;
    type?: string | null;
    doctorName?: string | null;
    npi?: string | null;
    searchKey?: string | null;
    specialty?: string | null;
    specialty1?: string | null;
    specialty2?: string | null;
    specialty3?: string | null;
    specialty4?: string | null;
    specialty5?: string | null;
    dateTime?: string | null;
    location?: string | null;
    hospitalName?: string | null;
    ccn?: string | null;
    facilityName?: string | null;
    url?: string | null;
    sessionDuration?: string | null;
    tenantName?: string | null;
    userType?: string | null;
    userLocation?: string | null;
    zipCode?: string | null;
    sex?: string | null;
    dateOfBirth?: string | null;
    benefitId?: string | null;
    actionType?: string | null;
    benefitUrl?: string | null;
    benefitName?: string | null;
    benefitRecommandationRating?: string | null;
  } | null> | null;
};

export type UserAttributeSettingsQuery = {
  __typename: "userAttributeResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  result?: Array<{
    __typename: "singleattributeResponse";
    Username: string;
    UserAttributes?: Array<{
      __typename: "attributeKeyPair";
      Name: string;
      Value: string;
    } | null> | null;
  } | null> | null;
};

export type UpdateCognitoUserSettingsQuery = {
  __typename: "cognitoUserAttributeResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "cognitoUsersList";
    firstName?: string | null;
    Username?: string | null;
    Enabled?: boolean | null;
    UserStatus?: string | null;
    lastName?: string | null;
    userType?: string | null;
    lastLoginTime?: string | null;
    employeeID?: string | null;
  } | null> | null;
};

export type GetSpecialtiesFilterQuery = {
  __typename: "filterSpeciaties";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "filterData";
    providerType?: string | null;
    specialtyList?: Array<{
      __typename: "filterSpeciatiesData";
      specialtyName?: string | null;
      taxonomyCode?: string | null;
      subSpecialtyList?: Array<{
        __typename: "filterSubSpecialties";
        subSpecialtyName?: string | null;
        taxList?: string | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type SendMailQuery = {
  __typename: "sesMailResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
};

export type GetAppVersionConfigDataQuery = {
  __typename: "appVersionConfig";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  result?: string | null;
};

export type AddUserProviderReviewQuery = {
  __typename: "userProviderReview";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PaginationResponse";
    totalCount?: number | null;
    pageLimit?: number | null;
    page?: number | null;
    avgDataList?: Array<{
      __typename: "avgData";
      avgCommunicatioRating?: string | null;
      avgOutcomeResultsRating?: string | null;
    } | null> | null;
  } | null;
  result?: Array<{
    __typename: "userReview";
    userId: string;
    npiId: string;
    easeOfCommunicatioRating?: string | null;
    outcomeResultsRating?: string | null;
    writingReview?: string | null;
    username?: string | null;
    updatedDate?: string | null;
  } | null> | null;
};

export type GetUserProviderReviewQuery = {
  __typename: "userProviderReview";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PaginationResponse";
    totalCount?: number | null;
    pageLimit?: number | null;
    page?: number | null;
    avgDataList?: Array<{
      __typename: "avgData";
      avgCommunicatioRating?: string | null;
      avgOutcomeResultsRating?: string | null;
    } | null> | null;
  } | null;
  result?: Array<{
    __typename: "userReview";
    userId: string;
    npiId: string;
    easeOfCommunicatioRating?: string | null;
    outcomeResultsRating?: string | null;
    writingReview?: string | null;
    username?: string | null;
    updatedDate?: string | null;
  } | null> | null;
};

export type GetReviewGuidelinesQuery = {
  __typename: "reviewGuidelines";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  result?: string | null;
};

export type GetInNetworkProvidersQuery = {
  __typename: "checkInNetwork";
  result?: string | null;
};

export type GetTreatmentCenterQuery = {
  __typename: "treatmentCenter";
  result?: {
    __typename: "treatmentCenterObj";
    page?: string | null;
    totalPages?: string | null;
    recordCount?: string | null;
    rows?: Array<{
      __typename: "RowsData";
      _irow?: string | null;
      name1?: string | null;
      name2?: string | null;
      street1?: string | null;
      city?: string | null;
      street2?: string | null;
      state?: string | null;
      zip?: string | null;
      phone?: string | null;
      intake1?: string | null;
      hotline1?: string | null;
      website?: string | null;
      latitude?: string | null;
      longitude?: string | null;
      miles?: string | null;
      services?: Array<{
        __typename: "ServicesData";
        f1?: string | null;
        f2?: string | null;
        f3?: string | null;
      } | null> | null;
      typeFacility?: string | null;
    } | null> | null;
  } | null;
};

export type GetProviderSearchQuery = {
  __typename: "providerSearch";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  result?: string | null;
};

export type GetSessionuserDataQuery = {
  __typename: "getSessionUser";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  result?: Array<{
    __typename: "getSessionUserResult";
    sub?: string | null;
    code?: string | null;
    state?: string | null;
  } | null> | null;
};

export type GetHyperLocalSeachDataQuery = {
  __typename: "gethyperLocalSearchResp";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  result?: Array<{
    __typename: "gethyperLocalSearchResult";
    name?: string | null;
    npi?: string | null;
    type?: string | null;
    sex?: string | null;
    elderDependence?: string | null;
    childDependence?: string | null;
    language?: string | null;
    specialty?: string | null;
  } | null> | null;
};

export type filterOsField = {
  gender?: string | null;
  acceptingNewPatients?: string | null;
  boardCertFilter?: string | null;
  specCode?: string | null;
  hospitalSysFilter?: string | null;
  hospitalAffFilter?: string | null;  
  hospitalGroupFilter?: string | null;
  simbiqScore?: string | null;
  translationServices?: string | null;
  nearTransit?: string | null;
};

export type filterFacility = {
  facilityTypeFilter?: string | null;
};

export type getTipMessage = {
  __typename: "getTipMessage";
  status?: SuccessResponse | null;
  result?: Array<getTipMsg | null> | null;
};

export type getTipMsg = {
  __typename: "getTipMsg";
  tipId?: number | null;
  messages?: string | null;
  clientId?: string | null;
  displayCount?: string | null;
  lastDisplayed?: string | null;
  subId?: string | null;
};

export type GetTipDetailsMessageQuery = {
  __typename: "getTipMessage";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  result?: Array<{
    __typename: "getTipMsg";
    tipId?: number | null;
    messages?: string | null;
    clientId?: string | null;
    displayCount?: string | null;
    lastDisplayed?: string | null;
    subId?: string | null;
  } | null> | null;
};

export type UpdateTipDetailsMessageQuery = {
  __typename: "getTipMessage";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  result?: Array<{
    __typename: "getTipMsg";
    tipId?: number | null;
    messages?: string | null;
    clientId?: string | null;
    displayCount?: string | null;
    lastDisplayed?: string | null;
    subId?: string | null;
  } | null> | null;
};

export type UpdateTipMessageQuery = {
  __typename: "getTipMessage";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  result?: Array<{
    __typename: "getTipMsg";
    tipId?: number | null;
    messages?: string | null;
    clientId?: string | null;
    displayCount?: string | null;
    lastDisplayed?: string | null;
    subId?: string | null;
  } | null> | null;
};

export type DeleteTipMessageQuery = {
  __typename: "getTipMessage";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  result?: Array<{
    __typename: "getTipMsg";
    tipId?: number | null;
    messages?: string | null;
    clientId?: string | null;
    displayCount?: string | null;
    lastDisplayed?: string | null;
    subId?: string | null;
  } | null> | null;
};

export type GetSdohDomainTopicsQuery = {
  __typename: "getSdohDomainTopicsData";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "getSdohDomainTopicsDataResponse";
    domain?: string | null;
    topics?: Array<topicsData | null> | null;
  } | null> | null;
};

export type GetSdohZctaAHRQDataQuery = {
  __typename: "getSdohZctaAHRQDataResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "getSdohZctaAHRQResult";
    variableName?: string | null;
    variableLabel?: string | null;
    value?: string | null;
  } | null> | null;
};

export type GetAllSdohZipCodeQuery = {
  __typename: "getAllSdoZhipCodeResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "getAllSdoZhipCodeObj";
    code?: string | null;
  } | null> | null;
};

export type GetAccessQualityCareQuery = {
  __typename: "getAccessQualityCareResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "getAccessQualityCareObj";
    ZIPCODE?: string | null;
    STATE?: string | null;
    REGION?: string | null;
    ACS_PCT_MEDICAID_ANY_ZC?: string | null;
    ACS_PCT_MEDICAID_ANY_BELOW64_ZC?: string | null;
    ACS_PCT_MEDICARE_ONLY_ZC?: string | null;
    ACS_PCT_OTHER_INS_ZC?: string | null;
    ACS_PCT_PVT_EMPL_DRCT_ZC?: string | null;
    ACS_PCT_PVT_EMPL_DRCT_BELOW64_ZC?: string | null;
    ACS_PCT_PRIVATE_ANY_ZC?: string | null;
    ACS_PCT_PRIVATE_ANY_BELOW64_ZC?: string | null;
    ACS_PCT_PRIVATE_EMPL_ZC?: string | null;
    ACS_PCT_PRIVATE_EMPL_BELOW64_ZC?: string | null;
    ACS_PCT_PRIVATE_MDCR_ZC?: string | null;
    ACS_PCT_PRIVATE_MDCR_35_64_ZC?: string | null;
    ACS_PCT_PRIVATE_OTHER_ZC?: string | null;
    ACS_PCT_PRIVATE_OTHER_BELOW64_ZC?: string | null;
    ACS_PCT_PRIVATE_SELF_ZC?: string | null;
    ACS_PCT_PRIVATE_SELF_BELOW64_ZC?: string | null;
    ACS_PCT_PUBLIC_ONLY_ZC?: string | null;
    ACS_PCT_PUBLIC_OTHER_ZC?: string | null;
    ACS_PCT_PUBLIC_OTHER_BELOW64_ZC?: string | null;
    ACS_PCT_SELF_MDCR_ABOVE35_ZC?: string | null;
    ACS_PCT_TRICARE_VA_ZC?: string | null;
    ACS_PCT_TRICARE_VA_BELOW64_ZC?: string | null;
    ACS_PCT_UNINSURED_ZC?: string | null;
    ACS_PCT_UNINSURED_BELOW64_ZC?: string | null;
    HIFLD_DIST_UC_ZP?: string | null;
    POS_DIST_ED_ZP?: string | null;
    POS_DIST_MEDSURG_ICU_ZP?: string | null;
    POS_DIST_TRAUMA_ZP?: string | null;
    POS_DIST_PED_ICU_ZP?: string | null;
    POS_DIST_OBSTETRICS_ZP?: string | null;
    POS_DIST_CLINIC_ZP?: string | null;
    POS_DIST_ALC_ZP?: string | null;
    CEN_AIAN_NH_IND?: string | null;
    CCBP_TOT_HOME_ZP?: string | null;
    CDCP_ARTHRITIS_ADULT_C_ZC?: string | null;
    CDCP_ASTHMA_ADULT_C_ZC?: string | null;
    CDCP_BLOOD_MED_ADULT_C_ZC?: string | null;
    CDCP_CHOLES_ADULT_C_ZC?: string | null;
    CDCP_CHOLES_SCR_ADULT_C_ZC?: string | null;
    CDCP_DOCTOR_VISIT_ADULT_C_ZC?: string | null;
    CDCP_KIDNEY_DISEASE_ADULT_C_ZC?: string | null;
    CDCP_NO_PHY_ACTV_ADULT_C_ZC?: string | null;
    CDCP_PULMONARY_ADULT_C_ZC?: string | null;
    CDCP_CERVCAN_SCR_F21_65_C_ZC?: string | null;
    CDCP_DENTIST_VISIT_ADULT_C_ZC?: string | null;
    CDCP_FOBT_SIG_COL_5String_75_C_ZC?: string | null;
    CDCP_MAMMO_SCR_F5String_74_C_ZC?: string | null;
    CDCP_NO_TEETH_65_ABOVE_C_ZC?: string | null;
    CDCP_PREV_SERV_F65_ABOVE_C_ZC?: string | null;
    CDCP_PREV_SERV_M65_ABOVE_C_ZC?: string | null;
    CDCP_SLEEP_LESS7HR_ADULT_C_ZC?: string | null;
  } | null> | null;
};

export type GetAccessQualityDescriptionQuery = {
  __typename: "accessQualityDescriptionResponse";
  status?: {
    __typename: "SuccessResponse";
    statusCode: number;
    msg: string;
  } | null;
  response?: {
    __typename: "PageResponse";
    totalCount: number;
    pageLimit: number;
    page: number;
    offset: number;
    totalRecordView: number;
  } | null;
  result?: Array<{
    __typename: "accessQualityDescriptionResult";
    variableName?: string | null;
    variableLabel?: string | null;
  } | null> | null;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async GetCondtionTabData(
    searchValue?: string,
    pagination?: paginationField,
    sort?: sortFiled
  ): Promise<GetCondtionTabDataQuery> {
    const statement = `query GetCondtionTabData($searchValue: String, $pagination: paginationField, $sort: sortFiled) {
        getCondtionTabData(
          searchValue: $searchValue
          pagination: $pagination
          sort: $sort
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            taxList
            displayName
            description
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (searchValue) {
      gqlAPIServiceArguments.searchValue = searchValue;
    }
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    if (sort) {
      gqlAPIServiceArguments.sort = sort;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCondtionTabDataQuery>response.data.getCondtionTabData;
  }
  async GetProcedureTabData(
    searchValue?: string,
    pagination?: paginationField
  ): Promise<GetProcedureTabDataQuery> {
    const statement = `query GetProcedureTabData($searchValue: String, $pagination: paginationField) {
        getProcedureTabData(searchValue: $searchValue, pagination: $pagination) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            taxList
            displayName
            description
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (searchValue) {
      gqlAPIServiceArguments.searchValue = searchValue;
    }
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProcedureTabDataQuery>response.data.getProcedureTabData;
  }
  async GetSearchPhraseSpecialtiesData(
    SearchPhrase: string,
    pagination?: paginationField,
    sort?: sortFiled
  ): Promise<GetSearchPhraseSpecialtiesDataQuery> {
    const statement = `query GetSearchPhraseSpecialtiesData($SearchPhrase: String!, $pagination: paginationField, $sort: sortFiled) {
        getSearchPhraseSpecialtiesData(
          SearchPhrase: $SearchPhrase
          pagination: $pagination
          sort: $sort
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            DisplayName
            PhraseType
            ProviderGroup
            SearchType
            TypeID
            TaxList
            Npi
            Ccn
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      SearchPhrase
    };
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    if (sort) {
      gqlAPIServiceArguments.sort = sort;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSearchPhraseSpecialtiesDataQuery>(
      response.data.getSearchPhraseSpecialtiesData
    );
  }
  async GetOsSearchPhraseSpecialtiesData(
    SearchPhrase?: string,
    cityState?: string,
    location?: string,
    normalSeachPhrase?: string,
    fieldSearch?: string,
	  distance?: string,				  
    filterBy?: filterOsField,
    pagination?: paginationField,
    sortBy?: sortFiled
  ): Promise<GetOsSearchPhraseSpecialtiesDataQuery> {
    const statement = `query GetOsSearchPhraseSpecialtiesData($SearchPhrase: String, $cityState: String, $location: String, $normalSeachPhrase: String, $fieldSearch: String, $distance: String, $filterBy:filterOsField, $pagination: paginationField, $sortBy: sortFiled) {
        getOsSearchPhraseSpecialtiesData(
          SearchPhrase: $SearchPhrase
          cityState: $cityState
          location: $location
          normalSeachPhrase: $normalSeachPhrase
          fieldSearch: $fieldSearch
		      distance: $distance		
          filterBy: $filterBy	 
          pagination: $pagination
          sortBy: $sortBy
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (SearchPhrase) {
      gqlAPIServiceArguments.SearchPhrase = SearchPhrase;
    }
    if (cityState) {
      gqlAPIServiceArguments.cityState = cityState;
    }
    if (location) {
      gqlAPIServiceArguments.location = location;
    }
    if (normalSeachPhrase) {
      gqlAPIServiceArguments.normalSeachPhrase = normalSeachPhrase;
    }
    if (fieldSearch) {
      gqlAPIServiceArguments.fieldSearch = fieldSearch;
    }
	  if (distance) {
      gqlAPIServiceArguments.distance = distance;
    }	
    if (filterBy) {
      gqlAPIServiceArguments.filterBy = filterBy;
    }	
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    if (sortBy) {
      gqlAPIServiceArguments.sortBy = sortBy;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOsSearchPhraseSpecialtiesDataQuery>(
      response.data.getOsSearchPhraseSpecialtiesData
    );
  }
  async GetOsFacilitiesData(
    SearchPhrase?: string,
    cityState?: string,
    location?: string,
    distance?: number,
    filterBy?:filterFacility,
    specialityName?: string,
    pagination?: paginationField,
    sort?: sortFiled
  ): Promise<GetOsFacilitiesDataQuery> {
    const statement = `query GetOsFacilitiesData($SearchPhrase: String, $cityState: String, $location: String, $distance: Int, $filterBy:filterFacility, $specialityName: String, $pagination: paginationField, $sort: sortFiled) {
        getOsFacilitiesData(
          SearchPhrase: $SearchPhrase
          cityState: $cityState
          location: $location
          distance: $distance
          filterBy: $filterBy
          specialityName: $specialityName
          pagination: $pagination
          sort: $sort
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            filterData {
              __typename
              ccn
              facilityID
              facilityName
              facilityType
              phone
              clia
              hospitalName
              medicalSchoolAffiliation
              caseMixIndex
              hospitalType
              simbiqScore
              primaryNearest
              cityState
              primaryLocation {
                __typename
                locationAddress
                locationName
                milesAway
                phone
                lat
                lng
              }
              primaryTransit {
                __typename
                stop_name
                stop_desc
                stop_lat
                stop_lon
                stop_url
                wheelchair_boarding
                vehicle_type
                stop_addres
                milesAway
              }
              centerPoint
              provType
            }
            findatDistanceKm
            hospitalSysFilter {
              __typename
              name
            }
            hospitalFacilityFilter {
              __typename
              name
            }
            facilityTypeFilter {
              __typename
              name
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (SearchPhrase) {
      gqlAPIServiceArguments.SearchPhrase = SearchPhrase;
    }
    if (cityState) {
      gqlAPIServiceArguments.cityState = cityState;
    }
    if (location) {
      gqlAPIServiceArguments.location = location;
    }
    if (distance) {
      gqlAPIServiceArguments.distance = distance;
    }
    if (filterBy) {
      gqlAPIServiceArguments.filterBy = filterBy;
    }
    if (specialityName) {
      gqlAPIServiceArguments.specialityName = specialityName;
    }
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    if (sort) {
      gqlAPIServiceArguments.sort = sort;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetOsFacilitiesDataQuery>response.data.getOsFacilitiesData;
  }
  async GetEmbededUrl(email: string): Promise<GetEmbededUrlQuery> {
    const statement = `query GetEmbededUrl($email: String!) {
        getEmbededUrl(email: $email) {
          __typename
          statusCode
          body
        }
      }`;
    const gqlAPIServiceArguments: any = {
      email
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEmbededUrlQuery>response.data.getEmbededUrl;
  }
  async GetStandardCharge(
    svcCodeType: string,
    pagination?: paginationField
  ): Promise<GetStandardChargeQuery> {
    const statement = `query GetStandardCharge($svcCodeType: String!, $pagination: paginationField) {
        getStandardCharge(svcCodeType: $svcCodeType, pagination: $pagination) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            status {
              __typename
              statusCode
              msg
            }
            response {
              __typename
              totalCount
              pageLimit
              page
              offset
              totalRecordView
            }
            result {
              __typename
              status {
                __typename
                statusCode
                msg
              }
              response {
                __typename
                totalCount
                pageLimit
                page
                offset
                totalRecordView
              }
              result {
                __typename
                status {
                  __typename
                  statusCode
                  msg
                }
                response {
                  __typename
                  totalCount
                  pageLimit
                  page
                  offset
                  totalRecordView
                }
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      svcCodeType
    };
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetStandardChargeQuery>response.data.getStandardCharge;
  }
  async GetProcedureServiceCode(
    description?: string,
    codeList?: string,
    pagination?: paginationField,
    sortBy?: sortFiled
  ): Promise<GetProcedureServiceCodeQuery> {
    const statement = `query GetProcedureServiceCode($description: String, $codeList: String, $pagination: paginationField, $sortBy: sortFiled) {
        getProcedureServiceCode(
          description: $description
          codeList: $codeList
          pagination: $pagination
          sortBy: $sortBy
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            code
            codeDesc
            codeType
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (description) {
      gqlAPIServiceArguments.description = description;
    }
    if (codeList) {
      gqlAPIServiceArguments.codeList = codeList;
    }
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    if (sortBy) {
      gqlAPIServiceArguments.sortBy = sortBy;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProcedureServiceCodeQuery>response.data.getProcedureServiceCode;
  }

  async GetInNetworkDetails(
    search?: string,
    code?: string,
    codeType?: string,
    pagination?: paginationField,
    sortBy?: sortFiled
  ): Promise<GetInNetworkDetailsQuery> {
    const statement = `query GetInNetworkDetails($search: String, $code: String, $codeType: String, $pagination: paginationField, $sortBy: sortFiled) {
        getInNetworkDetails(
          search: $search
          code: $code
          codeType: $codeType
          pagination: $pagination
          sortBy: $sortBy
        ){
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            payerName
            plans {
              __typename
              planName
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (search) {
      gqlAPIServiceArguments.search = search;
    }
    if (code) {
      gqlAPIServiceArguments.code = code;
    }
    if (codeType) {
      gqlAPIServiceArguments.codeType = codeType;
    }
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    if (sortBy) {
      gqlAPIServiceArguments.sortBy = sortBy;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetInNetworkDetailsQuery>response.data.getInNetworkDetails;
  }

  async GetStandardChargeData(
    code?: string,
    codeType?: string,
    cityState?: string,
    location?: string,
    radiusFilter?: string,
    filterBy?: filterByObj,
    pagination?: paginationField,
    sortBy?: sortFiled
  ): Promise<GetStandardChargeDataQuery> {
    const statement = `query GetStandardChargeData($code: String, $codeType: String, $cityState: String, $location: String, $radiusFilter: String, $filterBy: filterByObj, $pagination: paginationField, $sortBy: sortFiled) {
        getStandardChargeData(
          code: $code
          codeType: $codeType
          cityState: $cityState
          location: $location
          radiusFilter: $radiusFilter
          filterBy: $filterBy
          pagination: $pagination
          sortBy: $sortBy
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            svcCodeDescription
            code_1
            code_1_type
            code_2
            code_2_type
            code_3
            code_3_type
            code_4
            code_4_type
            modifierCode
            setting
            drug_unit_of_measurement
            drug_type_of_measurement
            standardGrossCharge
            standard_charge_discounted_cash
            payerName
            planName
            standard_charge_negotiated_dollar
            standard_charge_negotiated_percentage
            standard_charge_negotiated_algorithm
            estimated_amount
            standard_charge_methodology
            minCharge
            maxCharge
            additional_generic_notes
            hospital_name
            hospital_address
            facilityCCN
            facilityID
            ccnResultsFacility{
              __typename
              ccn
              city
              phone
              state
              street
              zipCode
              facilityID
              facilityName
              facilityType
              facilitySummary
              type
              coordinates{
                __typename
                lat
                lon
              }
              nearestKm
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (code) {
      gqlAPIServiceArguments.code = code;
    }
    if (codeType) {
      gqlAPIServiceArguments.codeType = codeType;
    }
    if (cityState) {
      gqlAPIServiceArguments.cityState = cityState;
    }
    if (location) {
      gqlAPIServiceArguments.location = location;
    }
    if (radiusFilter) {
      gqlAPIServiceArguments.radiusFilter = radiusFilter;
    }
    if (filterBy) {
      gqlAPIServiceArguments.filterBy = filterBy;
    }
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    if (sortBy) {
      gqlAPIServiceArguments.sortBy = sortBy;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetStandardChargeDataQuery>response.data.getStandardChargeData;
  }
  
  async GetSearchKeyTaxListData(
    chooseKey: string,
    chooseGroup?: string,
    pagination?: paginationField,
    sort?: sortFiled
  ): Promise<GetSearchKeyTaxListDataQuery> {
    const statement = `query GetSearchKeyTaxListData($chooseKey: String!, $chooseGroup: String, $pagination: paginationField, $sort: sortFiled) {
        getSearchKeyTaxListData(
          chooseKey: $chooseKey
          chooseGroup: $chooseGroup
          pagination: $pagination
          sort: $sort
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            DisplayName
            PhraseType
            ProviderGroup
            SearchType
            TypeID
            TaxList
            Npi
            Ccn
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      chooseKey
    };
    if (chooseGroup) {
      gqlAPIServiceArguments.chooseGroup = chooseGroup;
    }
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    if (sort) {
      gqlAPIServiceArguments.sort = sort;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSearchKeyTaxListDataQuery>response.data.getSearchKeyTaxListData;
  }
  
  async GetParameterDataByName(
    name: string
  ): Promise<GetParameterDataByNameQuery> {
    const statement = `query GetParameterDataByName($name: String!) {
        getParameterDataByName(name: $name) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          result {
            __typename
            Name
            Type
            Value
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      name
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetParameterDataByNameQuery>response.data.getParameterDataByName;
  }
  async GetTaxListByShortcutName(
    iconName: string,
    pagination?: paginationField,
    sort?: sortFiled
  ): Promise<GetTaxListByShortcutNameQuery> {
    const statement = `query GetTaxListByShortcutName($iconName: String!, $pagination: paginationField, $sort: sortFiled) {
        getTaxListByShortcutName(
          iconName: $iconName
          pagination: $pagination
          sort: $sort
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            taxList
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      iconName
    };
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    if (sort) {
      gqlAPIServiceArguments.sort = sort;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTaxListByShortcutNameQuery>(
      response.data.getTaxListByShortcutName
    );
  }
  async GetUserSettings(sub: string): Promise<GetUserSettingsQuery> {
    const statement = `query GetUserSettings($sub: String!) {
        getUserSettings(sub: $sub) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            DefaultLanguage
            TextNewResultSavedSearch
            TextFavoriteAcceptingNew
            TextAppointmentReminder
            EmailNewResultSavedSearch
            EmailFavoriteAcceptingNew
            EmailAppointmentReminder
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      sub
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserSettingsQuery>response.data.getUserSettings;
  }
  async GetSdohSource(
    tenantId?: string,
    subId?: string
  ): Promise<GetSdohSourceQuery> {
    const statement = `query GetSdohSource($tenantId: String, $subId: String) {
        getSdohSource(tenantId: $tenantId, subId: $subId) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            userSdohInfo {
              __typename
              subId
              userPoolId
              aboutSdoh {
                __typename
                domainName
                status
                correctAnswers {
                  __typename
                  questionCode
                  answerCode
                }
              }
            }
            sourceSdoh {
              __typename
              domainName
              questionAnswerInfo {
                __typename
                questionCode
                question
                answerType
                answers {
                  __typename
                  answerCode
                  answer
                }
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (tenantId) {
      gqlAPIServiceArguments.tenantId = tenantId;
    }
    if (subId) {
      gqlAPIServiceArguments.subId = subId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSdohSourceQuery>response.data.getSdohSource;
  }
  async GetTranslateText(
    text: string,
    targetLangCode: string,
    sourceLangCode?: string
  ): Promise<GetTranslateTextQuery> {
    const statement = `query GetTranslateText($text: String!, $sourceLangCode: String, $targetLangCode: String!) {
        getTranslateText(
          text: $text
          sourceLangCode: $sourceLangCode
          targetLangCode: $targetLangCode
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          result {
            __typename
            TranslatedText
            SourceLanguageCode
            TargetLanguageCode
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      text,
      targetLangCode
    };
    if (sourceLangCode) {
      gqlAPIServiceArguments.sourceLangCode = sourceLangCode;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTranslateTextQuery>response.data.getTranslateText;
  }
  async GetDomains(
    tenantId?: string,
    subId?: string
  ): Promise<GetDomainsQuery> {
    const statement = `query GetDomains($tenantId: String, $subId: String) {
        getDomains(tenantId: $tenantId, subId: $subId) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            status
            domainName
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (tenantId) {
      gqlAPIServiceArguments.tenantId = tenantId;
    }
    if (subId) {
      gqlAPIServiceArguments.subId = subId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDomainsQuery>response.data.getDomains;
  }
  async AddUpdateSdoh(
    tenantId?: string,
    subId?: string,
    domainName?: string,
    domainStatus?: number,
    answers?: Array<domainAnswersData | null>
  ): Promise<AddUpdateSdohQuery> {
    const statement = `query AddUpdateSdoh($tenantId: String, $subId: String, $domainName: String, $domainStatus: Int, $answers: [domainAnswersData]) {
        addUpdateSdoh(
          tenantId: $tenantId
          subId: $subId
          domainName: $domainName
          domainStatus: $domainStatus
          answers: $answers
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (tenantId) {
      gqlAPIServiceArguments.tenantId = tenantId;
    }
    if (subId) {
      gqlAPIServiceArguments.subId = subId;
    }
    if (domainName) {
      gqlAPIServiceArguments.domainName = domainName;
    }
    if (domainStatus) {
      gqlAPIServiceArguments.domainStatus = domainStatus;
    }
    if (answers) {
      gqlAPIServiceArguments.answers = answers;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AddUpdateSdohQuery>response.data.addUpdateSdoh;
  }
  async GetRecommendations(
    taxonomycode?: string,
    childDependentFlag?: string,
    elderDependentFlag?: string,
    subId?: string
  ): Promise<GetRecommendationsQuery> {
    const statement = `query GetRecommendations($taxonomycode: String, $childDependentFlag: String, $elderDependentFlag: String, $subId: String) {
        getRecommendations(
          taxonomycode: $taxonomycode
          childDependentFlag: $childDependentFlag
          elderDependentFlag: $elderDependentFlag
          subId: $subId
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            benefits {
              __typename
              benefitID
              benefitName
              benefitLogo
              benefitType
              benefitSummary
              benefitMemberID
              benefitSubscriberID
              benefitCost
              benefitGroupID
              benefitWebsite
              benefitWebsiteDisplayName
              benefitPhone
              benefitLoginInstructions
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (taxonomycode) {
      gqlAPIServiceArguments.taxonomycode = taxonomycode;
    }
    if (childDependentFlag) {
      gqlAPIServiceArguments.childDependentFlag = childDependentFlag;
    }
    if (elderDependentFlag) {
      gqlAPIServiceArguments.elderDependentFlag = elderDependentFlag;
    }
    if (subId) {
      gqlAPIServiceArguments.subId = subId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetRecommendationsQuery>response.data.getRecommendations;
  }
  async AddDismiss(
    benefitId?: string,
    subId?: string
  ): Promise<AddDismissQuery> {
    const statement = `query AddDismiss($benefitId: String, $subId: String) {
        addDismiss(benefitId: $benefitId, subId: $subId) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (benefitId) {
      gqlAPIServiceArguments.benefitId = benefitId;
    }
    if (subId) {
      gqlAPIServiceArguments.subId = subId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AddDismissQuery>response.data.addDismiss;
  }
  async UpdateProfileSettings(
    action?: string,
    communicationPreference?: communicationPreferenceObj,
    location?: string,
    userAttributes?: userAttributesObj,
    tenantId?: string,
    clientId?: string,
    benefits?: string,
    poolId?: string,
    subscribeId?: string,
    profiles?: Array<userProfileInputData | null>
  ): Promise<UpdateProfileSettingsQuery> {
    const statement = `query UpdateProfileSettings($action: String, $communicationPreference: communicationPreferenceObj, $location: String, $userAttributes: userAttributesObj, $tenantId: String, $clientId: String, $benefits: String, $poolId: String, $subscribeId: String, $profiles: [userProfileInputData]) {
        updateProfileSettings(
          action: $action
          communicationPreference: $communicationPreference
          location: $location
          userAttributes: $userAttributes
          tenantId: $tenantId
          clientId: $clientId
          benefits: $benefits
          poolId: $poolId
          subscribeId: $subscribeId
          profiles: $profiles
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (action) {
      gqlAPIServiceArguments.action = action;
    }
    if (communicationPreference) {
      gqlAPIServiceArguments.communicationPreference = communicationPreference;
    }
    if (location) {
      gqlAPIServiceArguments.location = location;
    }
    if (userAttributes) {
      gqlAPIServiceArguments.userAttributes = userAttributes;
    }
    if (tenantId) {
      gqlAPIServiceArguments.tenantId = tenantId;
    }
    if (clientId) {
      gqlAPIServiceArguments.clientId = clientId;
    }
    if (benefits) {
      gqlAPIServiceArguments.benefits = benefits;
    }
    if (poolId) {
      gqlAPIServiceArguments.poolId = poolId;
    }
    if (subscribeId) {
      gqlAPIServiceArguments.subscribeId = subscribeId;
    }
    if (profiles) {
      gqlAPIServiceArguments.profiles = profiles;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProfileSettingsQuery>response.data.updateProfileSettings;
  }
  async GetProfileSettings(
    clientId: string,
    poolId: string,
    tenantId?: string,
    sub?: string,
    subscribeId?: string
  ): Promise<GetProfileSettingsQuery> {
    const statement = `query GetProfileSettings($tenantId: String, $clientId: String!, $poolId: String!, $sub: String, $subscribeId: String) {
        getProfileSettings(
          tenantId: $tenantId
          clientId: $clientId
          poolId: $poolId
          sub: $sub
          subscribeId: $subscribeId
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            clientId
            memberId
            communication {
              __typename
              email
              text
            }
            location
            poolId
            subId
            subscribeId
            tenantId
            clientName
            memberAccessCode
            memberFullName
            memberSsnCode
            memberCity
            memberDOB
            memberEmailConsent
            memberPersonalEmail
            memberFirstName
            memberGenderIdentity
            memberHireDate
            memberLastName
            memberPhone
            memberPhoneType
            memberPreferredLanguage
            memberPreferredName
            memberSex
            memberStreetAddress1
            memberStreetAddress2
            memberTermDate
            memberTextConsent
            memberWorkEmail
            memberZipCode
            simbiqAccessCode
            subscriberFlag
            subscriberPlanType
            race
            ethnicity
            childDependentFlag
            elderDependentFlag
            userBenefits {
              __typename
              benefitID
              benefitName
              benefitLogo
              benefitType
              benefitSummary
              benefitMemberID
              benefitSubscriberID
              benefitCost
              benefitGroupID
              benefitWebsite
              benefitWebsiteDisplayName
              benefitPhone
              benefitLoginInstructions
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      clientId,
      poolId
    };
    if (tenantId) {
      gqlAPIServiceArguments.tenantId = tenantId;
    }
    if (sub) {
      gqlAPIServiceArguments.sub = sub;
    }
    if (subscribeId) {
      gqlAPIServiceArguments.subscribeId = subscribeId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProfileSettingsQuery>response.data.getProfileSettings;
  }
  async GetTenantConfig(userPoolId: string): Promise<GetTenantConfigQuery> {
    const statement = `query GetTenantConfig($userPoolId: String!) {
        getTenantConfig(userPoolId: $userPoolId) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            primaryFont
            pageTitle
            subTitle
            topBackground
            placeHolder
            textBlack
            iconHover
            borderGray
            cardBorder
            cardHover
            iconColor
            linkHover
            buttonHover
            filterChip
            filterchipBorder
            filterchipActive
            fchipactiveBorder
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userPoolId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTenantConfigQuery>response.data.getTenantConfig;
  }
  async UpdateTenantTemplate(
    userPoolId: string,
    elements?: addelementsObjData
  ): Promise<UpdateTenantTemplateQuery> {
    const statement = `query UpdateTenantTemplate($userPoolId: String!, $elements: addelementsObjData) {
        updateTenantTemplate(userPoolId: $userPoolId, elements: $elements) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userPoolId
    };
    if (elements) {
      gqlAPIServiceArguments.elements = elements;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTenantTemplateQuery>response.data.updateTenantTemplate;
  }
  async DeleteTenantTemplate(
    userPoolId: string
  ): Promise<DeleteTenantTemplateQuery> {
    const statement = `query DeleteTenantTemplate($userPoolId: String!) {
        deleteTenantTemplate(userPoolId: $userPoolId) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userPoolId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTenantTemplateQuery>response.data.deleteTenantTemplate;
  }
  async GetUserBenefits(
    userPoolId: string,
    userType: string
  ): Promise<GetUserBenefitsQuery> {
    const statement = `query GetUserBenefits($userPoolId: String!, $userType: String!) {
        getUserBenefits(userPoolId: $userPoolId, userType: $userType) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            benefits {
              __typename
              benefitID
              benefitName
              benefitLogo
              benefitType
              benefitSummary
              benefitMemberID
              benefitSubscriberID
              benefitCost
              benefitGroupID
              benefitWebsite
              benefitWebsiteDisplayName
              benefitPhone
              benefitLoginInstructions
            }
            clientData {
              __typename
              deptName
              tenantName
              availableHrs
              phone
              phoneInternal
              email
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userPoolId,
      userType
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserBenefitsQuery>response.data.getUserBenefits;
  }
  async DeleteUserBenefits(
    userPoolId: string
  ): Promise<DeleteUserBenefitsQuery> {
    const statement = `query DeleteUserBenefits($userPoolId: String!) {
        deleteUserBenefits(userPoolId: $userPoolId) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userPoolId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteUserBenefitsQuery>response.data.deleteUserBenefits;
  }
  async AddUserBenefits(
    userPoolId: string,
    name?: string,
    logoImg?: string,
    type?: string,
    desc?: string,
    url?: string,
    phone?: string,
    memberId?: string,
    username?: string,
    password?: string,
    fees?: string
  ): Promise<AddUserBenefitsQuery> {
    const statement = `query AddUserBenefits($userPoolId: String!, $name: String, $logoImg: String, $type: String, $desc: String, $url: String, $phone: String, $memberId: String, $username: String, $password: String, $fees: String) {
        addUserBenefits(
          userPoolId: $userPoolId
          name: $name
          logoImg: $logoImg
          type: $type
          desc: $desc
          url: $url
          phone: $phone
          memberId: $memberId
          username: $username
          password: $password
          fees: $fees
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userPoolId
    };
    if (name) {
      gqlAPIServiceArguments.name = name;
    }
    if (logoImg) {
      gqlAPIServiceArguments.logoImg = logoImg;
    }
    if (type) {
      gqlAPIServiceArguments.type = type;
    }
    if (desc) {
      gqlAPIServiceArguments.desc = desc;
    }
    if (url) {
      gqlAPIServiceArguments.url = url;
    }
    if (phone) {
      gqlAPIServiceArguments.phone = phone;
    }
    if (memberId) {
      gqlAPIServiceArguments.memberId = memberId;
    }
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    if (password) {
      gqlAPIServiceArguments.password = password;
    }
    if (fees) {
      gqlAPIServiceArguments.fees = fees;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AddUserBenefitsQuery>response.data.addUserBenefits;
  }
  async GetUserFavourites(
    sub: string,
    entityType: number,
    searchValue?: string,
    location?: string,
    cityState?: string,
    pagination?: paginationField,
    sort?: sortFiled
  ): Promise<GetUserFavouritesQuery> {
    const statement = `query GetUserFavourites($sub: String!, $entityType: Int!, $searchValue: String, $location: String, $cityState: String, $pagination: paginationField, $sort: sortFiled) {
        getUserFavourites(
          sub: $sub
          entityType: $entityType
          searchValue: $searchValue
          location: $location
          cityState: $cityState
          pagination: $pagination
          sort: $sort
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            providers {
              __typename
              ProviderNPI
              entityCode
              licenseState
              providerName
              medicalGraduationDate
              provYearsPracticing
              simbiqScore
              gender
              nearestKm
              nearestLocation
              primarySpecialty
              provOrgname
              acceptingNewPatients
              translationService
              faciliyAddress
              mipsScore
              placeHolderText
              primaryLocation {
                __typename
                locationAddress
                locationName
                milesAway
                phone
                lat
                lng
              }
              primaryTransit {
                __typename
                stop_name
                stop_desc
                stop_lat
                stop_lon
                stop_url
                wheelchair_boarding
                vehicle_type
                stop_addres
                milesAway
              }
              practiseResult {
                __typename
                locationName
                locationAddress
                milesAway
                lat
                lng
                locationType
              }
              otherLocationCount
              centerPoint
              boardCardsName
              disciplinaryAction {
                __typename
                provBoardAction
                provBoardActionDate
                provLicenseState
              }
              provType
            }
            facilities {
              __typename
              ccn
              facilityID
              facilityName
              facilityType
              facilitySummary
              costEstimatorLink
              standardChargeLink
              primaryLocation {
                __typename
                locationAddress
                locationName
                milesAway
                phone
                lat
                lng
              }
              phone
              affiliatedProviders {
                __typename
                locationAddress
                providerName
              }
              services {
                __typename
                name
              }
              departments {
                __typename
                name
              }
              drgList {
                __typename
                DRGNo
                DRGName
                DRGDischarges
                DRGRegionalDischargePct
              }
              primaryTransit {
                __typename
                stop_name
                stop_desc
                stop_lat
                stop_lon
                stop_url
                wheelchair_boarding
                vehicle_type
                stop_addres
                milesAway
              }
              measurements {
                __typename
                measureCode
                measureName
                score
                measureDateRange
              }
              hospitalRatingCount {
                __typename
                hospitalOwnership
                emergencyServices
                hospitalOverallRating
                safetyGroupMeasureCount
                readmGroupMeasureCount
                ptExpGroupMeasureCount
                teGroupMeasureCount
              }
              type
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      sub,
      entityType
    };
    if (searchValue) {
      gqlAPIServiceArguments.searchValue = searchValue;
    }
    if (location) {
      gqlAPIServiceArguments.location = location;
    }
    if (cityState) {
      gqlAPIServiceArguments.cityState = cityState;
    }
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    if (sort) {
      gqlAPIServiceArguments.sort = sort;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserFavouritesQuery>response.data.getUserFavourites;
  }
  async GetSavedSearch(
    sub: string,
    pagination?: paginationField,
    sort?: sortFiled
  ): Promise<GetSavedSearchQuery> {
    const statement = `query GetSavedSearch($sub: String!, $pagination: paginationField, $sort: sortFiled) {
        getSavedSearch(sub: $sub, pagination: $pagination, sort: $sort) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            keyword
            filters {
              __typename
              distance
              gender
              simbiqScore
              acceptingNewPatients
            }
            taxList
            date
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      sub
    };
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    if (sort) {
      gqlAPIServiceArguments.sort = sort;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSavedSearchQuery>response.data.getSavedSearch;
  }
  async UpdateSettings(
    sub: string,
    npi: string,
    updateTab: string,
    provType?: string,
    taxList?: string,
    searchName?: string,
    keyword?: string,
    filters?: filterField
  ): Promise<UpdateSettingsQuery> {
    const statement = `query UpdateSettings($sub: String!, $provType: String, $npi: String!, $taxList: String, $updateTab: String!, $searchName: String, $keyword: String, $filters: filterField) {
        updateSettings(
          sub: $sub
          provType: $provType
          npi: $npi
          taxList: $taxList
          updateTab: $updateTab
          searchName: $searchName
          keyword: $keyword
          filters: $filters
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      sub,
      npi,
      updateTab
    };
    if (provType) {
      gqlAPIServiceArguments.provType = provType;
    }
    if (taxList) {
      gqlAPIServiceArguments.taxList = taxList;
    }
    if (searchName) {
      gqlAPIServiceArguments.searchName = searchName;
    }
    if (keyword) {
      gqlAPIServiceArguments.keyword = keyword;
    }
    if (filters) {
      gqlAPIServiceArguments.filters = filters;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateSettingsQuery>response.data.updateSettings;
  }
  async GetNpiInfoData(
    taxonomyCode: string,
    entityCode: number,
    postalCode?: string,
    zipCode?: string,
    location?: string,
    cityState?: string,
    countryCode?: string,
    pagination?: paginationField,
    sortBy?: sortFiled,
    filterBy?: filterField
  ): Promise<GetNpiInfoDataQuery> {
    const statement = `query GetNpiInfoData($taxonomyCode: String!, $postalCode: String, $zipCode: String, $location: String, $cityState: String, $countryCode: String, $entityCode: Int!, $pagination: paginationField, $sortBy: sortFiled, $filterBy: filterField) {
        getNpiInfoData(
          taxonomyCode: $taxonomyCode
          postalCode: $postalCode
          zipCode: $zipCode
          location: $location
          cityState: $cityState
          countryCode: $countryCode
          entityCode: $entityCode
          pagination: $pagination
          sortBy: $sortBy
          filterBy: $filterBy
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            filterData {
              __typename
              ProviderNPI
              entityCode
              licenseState
              providerName
              medicalGraduationDate
              provYearsPracticing
              simbiqScore
              gender
              nearestKm
              nearestLocation
              primarySpecialty
              provOrgname
              acceptingNewPatients
              translationService
              faciliyAddress
              mipsScore
              placeHolderText
              primaryLocation {
                __typename
                locationAddress
                locationName
                milesAway
                phone
                lat
                lng
              }
              primaryTransit {
                __typename
                stop_name
                stop_desc
                stop_lat
                stop_lon
                stop_url
                wheelchair_boarding
                vehicle_type
                stop_addres
                milesAway
              }
              practiseResult {
                __typename
                locationName
                locationAddress
                milesAway
                lat
                lng
                locationType
              }
              otherLocationCount
              centerPoint
              boardCardsName
              disciplinaryAction {
                __typename
                provBoardAction
                provBoardActionDate
                provLicenseState
              }
              provType
            }
            findatDistanceKm
            hospitalAffFilter {
              __typename
              name
            }
            hospitalSysFilter {
              __typename
              name
            }
            specialtyFilter {
              __typename
              name
              taxCode
              type
            }
            hospitalGroupFilter {
              __typename
              name
            }
            boardCertFilter {
              __typename
              name
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      taxonomyCode,
      entityCode
    };
    if (postalCode) {
      gqlAPIServiceArguments.postalCode = postalCode;
    }
    if (zipCode) {
      gqlAPIServiceArguments.zipCode = zipCode;
    }
    if (location) {
      gqlAPIServiceArguments.location = location;
    }
    if (cityState) {
      gqlAPIServiceArguments.cityState = cityState;
    }
    if (countryCode) {
      gqlAPIServiceArguments.countryCode = countryCode;
    }
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    if (sortBy) {
      gqlAPIServiceArguments.sortBy = sortBy;
    }
    if (filterBy) {
      gqlAPIServiceArguments.filterBy = filterBy;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetNpiInfoDataQuery>response.data.getNpiInfoData;
  }
  async GetNpiHospitalInfoData(
    providerGroup: string,
    taxonomyCode?: string,
    postalCode?: string,
    zipCode?: string,
    location?: string,
    cityState?: string,
    countryCode?: string,
    pagination?: paginationField,
    sortBy?: sortFiled,
    filterBy?: filterField,
    shortCutName?: string
  ): Promise<GetNpiHospitalInfoDataQuery> {
    const statement = `query GetNpiHospitalInfoData($taxonomyCode: String, $postalCode: String, $zipCode: String, $location: String, $cityState: String, $countryCode: String, $pagination: paginationField, $sortBy: sortFiled, $filterBy: filterField, $providerGroup: String!, $shortCutName: String) {
        getNpiHospitalInfoData(
          taxonomyCode: $taxonomyCode
          postalCode: $postalCode
          zipCode: $zipCode
          location: $location
          cityState: $cityState
          countryCode: $countryCode
          pagination: $pagination
          sortBy: $sortBy
          filterBy: $filterBy
          providerGroup: $providerGroup
          shortCutName: $shortCutName
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            filterData {
              __typename
              ccn
              facilityID
              facilityName
              facilityType
              phone
              clia
              hospitalName
              medicalSchoolAffiliation
              caseMixIndex
              hospitalType
              simbiqScore
              primaryNearest
              cityState
              primaryLocation {
                __typename
                locationAddress
                locationName
                milesAway
                phone
                lat
                lng
              }
              primaryTransit {
                __typename
                stop_name
                stop_desc
                stop_lat
                stop_lon
                stop_url
                wheelchair_boarding
                vehicle_type
                stop_addres
                milesAway
              }
              centerPoint
              provType
            }
            findatDistanceKm
            hospitalSysFilter {
              __typename
              name
            }
            hospitalFacilityFilter {
              __typename
              name
            }
            facilityTypeFilter {
              __typename
              name
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      providerGroup
    };
    if (taxonomyCode) {
      gqlAPIServiceArguments.taxonomyCode = taxonomyCode;
    }
    if (postalCode) {
      gqlAPIServiceArguments.postalCode = postalCode;
    }
    if (zipCode) {
      gqlAPIServiceArguments.zipCode = zipCode;
    }
    if (location) {
      gqlAPIServiceArguments.location = location;
    }
    if (cityState) {
      gqlAPIServiceArguments.cityState = cityState;
    }
    if (countryCode) {
      gqlAPIServiceArguments.countryCode = countryCode;
    }
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    if (sortBy) {
      gqlAPIServiceArguments.sortBy = sortBy;
    }
    if (filterBy) {
      gqlAPIServiceArguments.filterBy = filterBy;
    }
    if (shortCutName) {
      gqlAPIServiceArguments.shortCutName = shortCutName;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetNpiHospitalInfoDataQuery>response.data.getNpiHospitalInfoData;
  }
  async GetSpecialityTabData(
    pagination?: paginationField,
    searchValue?: string
  ): Promise<GetSpecialityTabDataQuery> {
    const statement = `query GetSpecialityTabData($pagination: paginationField, $searchValue: String) {
        getSpecialityTabData(pagination: $pagination, searchValue: $searchValue) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            specialtycode
            specialtyname
            description
            subSpecresult {
              __typename
              subspecialtycode
              subspecialtyname
              description
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    if (searchValue) {
      gqlAPIServiceArguments.searchValue = searchValue;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSpecialityTabDataQuery>response.data.getSpecialityTabData;
  }
  async GetUserAttemptReport(
    userReport?: string
  ): Promise<GetUserAttemptReportQuery> {
    const statement = `query GetUserAttemptReport($userReport: String) {
        getUserAttemptReport(userReport: $userReport) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userReport) {
      gqlAPIServiceArguments.userReport = userReport;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserAttemptReportQuery>response.data.getUserAttemptReport;
  }
  async GetCityStateData(cityState: string): Promise<GetCityStateDataQuery> {
    const statement = `query GetCityStateData($cityState: String!) {
        getCityStateData(cityState: $cityState) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          result {
            __typename
            zipcode
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      cityState
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCityStateDataQuery>response.data.getCityStateData;
  }
  async GetProviderDetailData(
    npiCode: string,
    provType?: string,
    location?: string,
    cityState?: string
  ): Promise<GetProviderDetailDataQuery> {
    const statement = `query GetProviderDetailData($npiCode: String!, $provType: String, $location: String, $cityState: String) {
        getProviderDetailData(
          npiCode: $npiCode
          provType: $provType
          location: $location
          cityState: $cityState
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            npi
            entityCode
            licenseState
            year
            phoneNumber
            primaryZipCode
            primaryTopLocation
            primaryMilesAway
            medicalGraduationDate
            provYearsPracticing
            translationService
            licenseNumber
            providerName
            proFirst
            proMiddle
            proLast
            provName
            provGender
            medicalGraduation
            provTaxList
            medicalGraduationSchool
            acceptingNewPatients
            provPostGradList {
              __typename
              gradName
            }
            provHonors {
              __typename
              honors
            }
            provPublications {
              __typename
              publications
            }
            provOrgName
            provGroupSpecialtyType
            provGroupName
            primarySpecialty
            simbiqScore
            provInsuranceAccepted
            provAcceptingMedicaid
            provMedicareParticipating
            provLocationList {
              __typename
              CCN
              City
              State
              ZipCode
              Latitude
              Longitude
              GroupPacID
              GroupMembers
              LocationName
              HealthSystemID
              StreetAddress1
              StreetAddress2
              HealthSystemCity
              HealthSystemName
              AcuteHospitalFlag
              HealthSystemState
              LocationAddress
              milesAway
              phone
              primaryTransit {
                __typename
                stop_name
                stop_desc
                stop_lat
                stop_lon
                stop_url
                wheelchair_boarding
                vehicle_type
                stop_addres
                milesAway
              }
            }
            provGroupAffiliationList {
              __typename
              City
              StreetAddress1
              StreetAddress2
              AcuteHospitalFlag
              Longitude
              ZipCode
              LocationName
              GroupName
              GroupMembers
              GroupPacID
              State
              CCN
              Latitude
              facilitySubType
              SimbiqScore
              MedicalSchoolAffiliation
              facilityType
              caseMixIndex
              LocationAddress
              milesAway
              phone
            }
            provHospitalAffiliationList {
              __typename
              City
              StreetAddress1
              StreetAddress2
              AcuteHospitalFlag
              Longitude
              ZipCode
              LocationName
              GroupName
              GroupMembers
              GroupPacID
              State
              CCN
              Latitude
              facilitySubType
              SimbiqScore
              MedicalSchoolAffiliation
              facilityType
              caseMixIndex
              LocationAddress
              milesAway
              phone
            }
            provSystemAffiliationList {
              __typename
              total_mds
              sys_beds
              acutehosp_cnt
              grp_cnt
              prim_care_mds
              hosp_cnt
              sys_multistate
              sys_teachint
              HealthSystemID
              HealthSystemCity
              HealthSystemName
              HealthSystemState
            }
            expertise {
              __typename
              ccsAvgAnnualPatientVolume
              ccsProcCodeName
              stateAvgAnnualSvcVolume
              ccsPercentAvgAnnualPatientVolume
              stateAvgAnnualPatientVolume
              ccsPercentAvgAnnualSvcVolume
              ccsProcCode
              ccsAvgAnnualSvcVolume
            }
            noOfHonour
            noOfPublication
            providerTraining {
              __typename
              ProvTrainingFromDate
              ProvTrainingEndDate
              ProvTrainingLocation
              ProvTrainingSpecialty
              ProvTrainingType
            }
            boardCards {
              __typename
              specialty
              certCard
              subspecCard
            }
            disciplinaryAction {
              __typename
              provBoardAction
              provBoardActionDate
              provLicenseState
            }
            ProviderServices {
              __typename
              HCPCS_Code
              Description
              Drug
              Number_of_Services
              Place_of_Service
              Number_of_Beneficiaries
              Average_Submitted_Charge
              Average_Medicare_Allowed_Amount
              Average_Medicare_Payment
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      npiCode
    };
    if (provType) {
      gqlAPIServiceArguments.provType = provType;
    }
    if (location) {
      gqlAPIServiceArguments.location = location;
    }
    if (cityState) {
      gqlAPIServiceArguments.cityState = cityState;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProviderDetailDataQuery>response.data.getProviderDetailData;
  }
  async GetProviderHospitalDetailData(
    ccnCode: string,
    provType?: string,
    location?: string,
    cityState?: string
  ): Promise<GetProviderHospitalDetailDataQuery> {
    const statement = `query GetProviderHospitalDetailData($ccnCode: String!, $provType: String, $location: String, $cityState: String) {
        getProviderHospitalDetailData(
          ccnCode: $ccnCode
          provType: $provType
          location: $location
          cityState: $cityState
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            ccn
            name
            website
            about
            simbiqScore
            primaryLocation {
              __typename
              locationAddress
              locationName
              milesAway
              phone
              lat
              lng
            }
            phoneNumber
            acutualFlag
            cohort
            hospitalType
            cityState
            primaryLat
            primaryLong
            primaryNearest
            locationList {
              __typename
              locationAddress
              locationName
              milesAway
              phone
              lat
              lng
              primaryTransit {
                __typename
                stop_name
                stop_desc
                stop_lat
                stop_lon
                stop_url
                wheelchair_boarding
                vehicle_type
                stop_addres
                milesAway
              }
            }
            locationCount
            topDRGList {
              __typename
              DRGRegionalDischargePct
              DRGDischarges
              DRG
            }
            serviceAndDepartments {
              __typename
              name
            }
            healthSystemDetails {
              __typename
              city
              state
              name
              totalMds
              sysBeds
              hospCnt
              grpCnt
              primeCareMds
            }
            providerAffiliationList {
              __typename
              provMiddleLast
              provSpecialtyList
              npi
              provNameLast
              provTaxList
              provCredential
            }
            caseMixIndex
            providerSpecialties {
              __typename
              TaxonomyCodeCount
              TaxCode
              DisplayName
            }
            commercialStateRP
            staffedBeds
            ervVisits
            opVisits
            alos
            ipDischarges
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      ccnCode
    };
    if (provType) {
      gqlAPIServiceArguments.provType = provType;
    }
    if (location) {
      gqlAPIServiceArguments.location = location;
    }
    if (cityState) {
      gqlAPIServiceArguments.cityState = cityState;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProviderHospitalDetailDataQuery>(
      response.data.getProviderHospitalDetailData
    );
  }
  async GetFacilityDetailData(
    facilityCode: string,
    provType?: string,
    location?: string,
    cityState?: string
  ): Promise<GetFacilityDetailDataQuery> {
    const statement = `query GetFacilityDetailData($facilityCode: String!, $provType: String, $location: String, $cityState: String) {
        getFacilityDetailData(
          facilityCode: $facilityCode
          provType: $provType
          location: $location
          cityState: $cityState
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            ccn
            facilityID
            facilityName
            facilityType
            facilitySummary
            costEstimatorLink
            standardChargeLink
            primaryLocation {
              __typename
              locationAddress
              locationName
              milesAway
              phone
              lat
              lng
            }
            phone
            affiliatedProviders {
              __typename
              locationAddress
              providerName
            }
            services {
              __typename
              name
            }
            departments {
              __typename
              name
            }
            drgList {
              __typename
              DRGNo
              DRGName
              DRGDischarges
              DRGRegionalDischargePct
            }
            primaryTransit {
              __typename
              stop_name
              stop_desc
              stop_lat
              stop_lon
              stop_url
              wheelchair_boarding
              vehicle_type
              stop_addres
              milesAway
            }
            measurements {
              __typename
              measureCode
              measureName
              score
              measureDateRange
            }
            hospitalRatingCount {
              __typename
              hospitalOwnership
              emergencyServices
              hospitalOverallRating
              safetyGroupMeasureCount
              readmGroupMeasureCount
              ptExpGroupMeasureCount
              teGroupMeasureCount
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      facilityCode
    };
    if (provType) {
      gqlAPIServiceArguments.provType = provType;
    }
    if (location) {
      gqlAPIServiceArguments.location = location;
    }
    if (cityState) {
      gqlAPIServiceArguments.cityState = cityState;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetFacilityDetailDataQuery>response.data.getFacilityDetailData;
  }
  async GetFacilityDetailDataNew(
    ccnCode?: string,
    provType?: string,
    location?: string,
    cityState?: string
  ): Promise<GetFacilityDetailDataNewQuery> {
    const statement = `query GetFacilityDetailDataNew($ccnCode: String, $provType: String, $location: String, $cityState: String) {
        getFacilityDetailDataNew(
          ccnCode: $ccnCode
          provType: $provType
          location: $location
          cityState: $cityState
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            ccn
            facilityID
            facilityName
            facilityType
            facilitySummary
            costEstimatorLink
            standardChargeLink
            primaryLocation {
              __typename
              locationAddress
              locationName
              milesAway
              phone
              lat
              lng
            }
            phone
            affiliatedProviders {
              __typename
              locationAddress
              providerName
            }
            services {
              __typename
              name
            }
            departments {
              __typename
              name
            }
            drgList {
              __typename
              DRGNo
              DRGName
              DRGDischarges
              DRGRegionalDischargePct
            }
            primaryTransit {
              __typename
              stop_name
              stop_desc
              stop_lat
              stop_lon
              stop_url
              wheelchair_boarding
              vehicle_type
              stop_addres
              milesAway
            }
            measurements {
              __typename
              measureCode
              measureName
              score
              measureDateRange
            }
            hospitalRatingCount {
              __typename
              hospitalOwnership
              emergencyServices
              hospitalOverallRating
              safetyGroupMeasureCount
              readmGroupMeasureCount
              ptExpGroupMeasureCount
              teGroupMeasureCount
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (ccnCode) {
      gqlAPIServiceArguments.ccnCode = ccnCode;
    }
    if (provType) {
      gqlAPIServiceArguments.provType = provType;
    }
    if (location) {
      gqlAPIServiceArguments.location = location;
    }
    if (cityState) {
      gqlAPIServiceArguments.cityState = cityState;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetFacilityDetailDataNewQuery>(
      response.data.getFacilityDetailDataNew
    );
  }
  async UpdateUserTrailLogs(
    userLogs?: userLogsObject
  ): Promise<UpdateUserTrailLogsQuery> {
    const statement = `query UpdateUserTrailLogs($userLogs: userLogsObject) {
        updateUserTrailLogs(userLogs: $userLogs) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userLogs) {
      gqlAPIServiceArguments.userLogs = userLogs;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateUserTrailLogsQuery>response.data.updateUserTrailLogs;
  }
  async GetUserTrailLogs(type?: string): Promise<GetUserTrailLogsQuery> {
    const statement = `query GetUserTrailLogs($type: String) {
        getUserTrailLogs(type: $type) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            userId
            type
            doctorName
            npi
            searchKey
            specialty
            specialty1
            specialty2
            specialty3
            specialty4
            specialty5
            dateTime
            location
            hospitalName
            ccn
            facilityName
            url
            sessionDuration
            tenantName
            userType
            userLocation
            zipCode
            sex
            dateOfBirth
            benefitId
            actionType
            benefitUrl
            benefitName
            benefitRecommandationRating
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (type) {
      gqlAPIServiceArguments.type = type;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserTrailLogsQuery>response.data.getUserTrailLogs;
  }
  async UserAttributeSettings(
    action: string,
    userPoolId?: string,
    userName?: string,
    setAttributes?: Array<setUpdateAttribute | null>,
    accessToken?: string
  ): Promise<UserAttributeSettingsQuery> {
    const statement = `query UserAttributeSettings($action: String!, $userPoolId: String, $userName: String, $setAttributes: [setUpdateAttribute], $accessToken: String) {
        userAttributeSettings(
          action: $action
          userPoolId: $userPoolId
          userName: $userName
          setAttributes: $setAttributes
          accessToken: $accessToken
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          result {
            __typename
            Username
            UserAttributes {
              __typename
              Name
              Value
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      action
    };
    if (userPoolId) {
      gqlAPIServiceArguments.userPoolId = userPoolId;
    }
    if (userName) {
      gqlAPIServiceArguments.userName = userName;
    }
    if (setAttributes) {
      gqlAPIServiceArguments.setAttributes = setAttributes;
    }
    if (accessToken) {
      gqlAPIServiceArguments.accessToken = accessToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UserAttributeSettingsQuery>response.data.userAttributeSettings;
  }
  async UpdateCognitoUserSettings(
    action: string,
    userPoolId?: string,
    userList?: Array<cognitoUserObj | null>,
    accessToken?: string,
    givenName?: string,
    userStatus?: string,
    pagination?: paginationField
  ): Promise<UpdateCognitoUserSettingsQuery> {
    const statement = `query UpdateCognitoUserSettings($action: String!, $userPoolId: String, $userList: [cognitoUserObj], $accessToken: String, $givenName: String, $userStatus: String, $pagination: paginationField) {
        updateCognitoUserSettings(
          action: $action
          userPoolId: $userPoolId
          userList: $userList
          accessToken: $accessToken
          givenName: $givenName
          userStatus: $userStatus
          pagination: $pagination
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            firstName
            Username
            Enabled
            UserStatus
            lastName
            userType
            lastLoginTime
            employeeID
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      action
    };
    if (userPoolId) {
      gqlAPIServiceArguments.userPoolId = userPoolId;
    }
    if (userList) {
      gqlAPIServiceArguments.userList = userList;
    }
    if (accessToken) {
      gqlAPIServiceArguments.accessToken = accessToken;
    }
    if (givenName) {
      gqlAPIServiceArguments.givenName = givenName;
    }
    if (userStatus) {
      gqlAPIServiceArguments.userStatus = userStatus;
    }
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCognitoUserSettingsQuery>(
      response.data.updateCognitoUserSettings
    );
  }
  async GetSpecialtiesFilter(
    taxonomyCode: string
  ): Promise<GetSpecialtiesFilterQuery> {
    const statement = `query GetSpecialtiesFilter($taxonomyCode: String!) {
        getSpecialtiesFilter(taxonomyCode: $taxonomyCode) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            providerType
            specialtyList {
              __typename
              specialtyName
              taxonomyCode
              subSpecialtyList {
                __typename
                subSpecialtyName
                taxList
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      taxonomyCode
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSpecialtiesFilterQuery>response.data.getSpecialtiesFilter;
  }
  async SendMail(template?: string, toMail?: string): Promise<SendMailQuery> {
    const statement = `query SendMail($template: String, $toMail: String) {
        sendMail(template: $template, toMail: $toMail) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (template) {
      gqlAPIServiceArguments.template = template;
    }
    if (toMail) {
      gqlAPIServiceArguments.toMail = toMail;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SendMailQuery>response.data.sendMail;
  }
  async GetAppVersionConfigData(
    action: string
  ): Promise<GetAppVersionConfigDataQuery> {
    const statement = `query GetAppVersionConfigData($action: String!) {
        getAppVersionConfigData(action: $action) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          result
        }
      }`;
    const gqlAPIServiceArguments: any = {
      action
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAppVersionConfigDataQuery>response.data.getAppVersionConfigData;
  }
  async AddUserProviderReview(
    userId: string,
    npiId: string,
    easeOfCommunicatioRating?: string,
    outcomeResultsRating?: string,
    writingReview?: string,
    username?: string
  ): Promise<AddUserProviderReviewQuery> {
    const statement = `query AddUserProviderReview($userId: String!, $npiId: String!, $easeOfCommunicatioRating: String, $outcomeResultsRating: String, $writingReview: String, $username: String) {
        addUserProviderReview(
          userId: $userId
          npiId: $npiId
          easeOfCommunicatioRating: $easeOfCommunicatioRating
          outcomeResultsRating: $outcomeResultsRating
          writingReview: $writingReview
          username: $username
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            avgDataList {
              __typename
              avgCommunicatioRating
              avgOutcomeResultsRating
            }
          }
          result {
            __typename
            userId
            npiId
            easeOfCommunicatioRating
            outcomeResultsRating
            writingReview
            username
            updatedDate
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId,
      npiId
    };
    if (easeOfCommunicatioRating) {
      gqlAPIServiceArguments.easeOfCommunicatioRating = easeOfCommunicatioRating;
    }
    if (outcomeResultsRating) {
      gqlAPIServiceArguments.outcomeResultsRating = outcomeResultsRating;
    }
    if (writingReview) {
      gqlAPIServiceArguments.writingReview = writingReview;
    }
    if (username) {
      gqlAPIServiceArguments.username = username;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AddUserProviderReviewQuery>response.data.addUserProviderReview;
  }
  async GetUserProviderReview(
    npiId: string,
    pagination?: paginationField
  ): Promise<GetUserProviderReviewQuery> {
    const statement = `query GetUserProviderReview($npiId: String!, $pagination: paginationField) {
        getUserProviderReview(npiId: $npiId, pagination: $pagination) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            avgDataList {
              __typename
              avgCommunicatioRating
              avgOutcomeResultsRating
            }
          }
          result {
            __typename
            userId
            npiId
            easeOfCommunicatioRating
            outcomeResultsRating
            writingReview
            username
            updatedDate
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      npiId
    };
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetUserProviderReviewQuery>response.data.getUserProviderReview;
  }
  async GetReviewGuidelines(
    bucketPath: string,
    fileName: string
  ): Promise<GetReviewGuidelinesQuery> {
    const statement = `query GetReviewGuidelines($bucketPath: String!, $fileName: String!) {
        getReviewGuidelines(bucketPath: $bucketPath, fileName: $fileName) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          result
        }
      }`;
    const gqlAPIServiceArguments: any = {
      bucketPath,
      fileName
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetReviewGuidelinesQuery>response.data.getReviewGuidelines;
  }
  async GetInNetworkProviders(
    insuranceName: string,
    name?: string,
    id?: string,
    pType?: string,
    npi?: string,
    zipCode?: string,
    latitude?: string,
    longitude?: string,
    networkCode?:string,
    city?:string,
    planName?:string,
  ): Promise<GetInNetworkProvidersQuery> {
    const statement = `query GetInNetworkProviders($insuranceName: String!, $name: String, $id: String, $pType: String, $npi: String, $zipCode: String, $latitude: String, $longitude: String, $networkCode: String, $city: String, $planName: String) {
        getInNetworkProviders(
          insuranceName: $insuranceName
          name: $name
          id: $id
          pType: $pType
          npi: $npi
          zipCode: $zipCode
          latitude: $latitude
          longitude: $longitude
          networkCode: $networkCode
          city: $city
          planName: $planName
        ) {
          __typename
          result
        }
      }`;
    const gqlAPIServiceArguments: any = {
      insuranceName
    };
    if (name) {
      gqlAPIServiceArguments.name = name;
    }
    if (id) {
      gqlAPIServiceArguments.id = id;
    }
    if (pType) {
      gqlAPIServiceArguments.pType = pType;
    }
    if (npi) {
      gqlAPIServiceArguments.npi = npi;
    }
    if (zipCode) {
      gqlAPIServiceArguments.zipCode = zipCode;
    }
    if (latitude) {
      gqlAPIServiceArguments.latitude = latitude;
    }
    if (longitude) {
      gqlAPIServiceArguments.longitude = longitude;
    }
    if (networkCode) {
      gqlAPIServiceArguments.networkCode = networkCode;
    }
    if (city) {
      gqlAPIServiceArguments.city = city;
    }
    if (planName) {
      gqlAPIServiceArguments.planName = planName;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetInNetworkProvidersQuery>response.data.getInNetworkProviders;
  }
  async GetTreatmentCenter(jsonUrl?: string): Promise<GetTreatmentCenterQuery> {
    const statement = `query GetTreatmentCenter($jsonUrl: String) {
        getTreatmentCenter(jsonUrl: $jsonUrl) {
          __typename
          result {
            __typename
            page
            totalPages
            recordCount
            rows {
              __typename
              _irow
              name1
              name2
              street1
              city
              street2
              state
              zip
              phone
              intake1
              hotline1
              website
              latitude
              longitude
              miles
              services {
                __typename
                f1
                f2
                f3
              }
              typeFacility
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (jsonUrl) {
      gqlAPIServiceArguments.jsonUrl = jsonUrl;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTreatmentCenterQuery>response.data.getTreatmentCenter;
  }
  async GetProviderSearch(
    descriptions: string
  ): Promise<GetProviderSearchQuery> {
    const statement = `query GetProviderSearch($descriptions: String!) {
        getProviderSearch(descriptions: $descriptions) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          result
        }
      }`;
    const gqlAPIServiceArguments: any = {
      descriptions
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProviderSearchQuery>response.data.getProviderSearch;
  }
	async GetSessionuserData(
    type: string,
    sub: string,
    code?: string,
    state?: string
  ): Promise<GetSessionuserDataQuery> {
    const statement = `query GetSessionuserData($type: String!, $sub: String!, $code: String, $state: String) {
        GetSessionuserData(type: $type, sub: $sub, code: $code, state: $state) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          result {
            __typename
            sub
            code
            state
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      type,
      sub
    };
    if (code) {
      gqlAPIServiceArguments.code = code;
    }
    if (state) {
      gqlAPIServiceArguments.state = state;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSessionuserDataQuery>response.data.GetSessionuserData;
  }
  async GetHyperLocalSeachData(
    type: string,
    searchData?: objSearchData,
	  count?:number
  ): Promise<GetHyperLocalSeachDataQuery> {
    const statement = `query GetHyperLocalSeachData($type: String!, $searchData: objSearchData, $count: Int) {
        getHyperLocalSeachData(type: $type, searchData: $searchData, count:$count) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          result {
            __typename
            name
            npi
            type
            sex
            elderDependence
            childDependence
            language
            specialty
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      type
    };
    if (searchData) {
      gqlAPIServiceArguments.searchData = searchData;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetHyperLocalSeachDataQuery>response.data.getHyperLocalSeachData;
  }
  async GetTipDetailsMessage(
    clientId: string,
    subId: string
  ): Promise<GetTipDetailsMessageQuery> {
    const statement = `query GetTipDetailsMessage($clientId: String!, $subId: String!) {
        getTipDetailsMessage(clientId: $clientId, subId: $subId) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          result {
            __typename
            tipId
            messages
            clientId
            displayCount
            lastDisplayed
            subId
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      clientId,
      subId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTipDetailsMessageQuery>response.data.getTipDetailsMessage;
  }
  async UpdateTipDetailsMessage(
    subId?: string,
    tipId?: number,
    displayCount?: number
  ): Promise<UpdateTipDetailsMessageQuery> {
    const statement = `query UpdateTipDetailsMessage($subId: String, $tipId: Int, $displayCount: Int) {
        updateTipDetailsMessage(
          subId: $subId
          tipId: $tipId
          displayCount: $displayCount
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          result {
            __typename
            tipId
            messages
            clientId
            displayCount
            lastDisplayed
            subId
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (subId) {
      gqlAPIServiceArguments.subId = subId;
    }
    if (tipId) {
      gqlAPIServiceArguments.tipId = tipId;
    }
    if (displayCount) {
      gqlAPIServiceArguments.displayCount = displayCount;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTipDetailsMessageQuery>response.data.updateTipDetailsMessage;
  }
  async UpdateTipMessage(
    tipId?: number,
    clientId?: string,
    messages?: string
  ): Promise<UpdateTipMessageQuery> {
    const statement = `query UpdateTipMessage($tipId: Int, $clientId: String, $messages: String) {
        updateTipMessage(tipId: $tipId, clientId: $clientId, messages: $messages) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          result {
            __typename
            tipId
            messages
            clientId
            displayCount
            lastDisplayed
            subId
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (tipId) {
      gqlAPIServiceArguments.tipId = tipId;
    }
    if (clientId) {
      gqlAPIServiceArguments.clientId = clientId;
    }
    if (messages) {
      gqlAPIServiceArguments.messages = messages;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTipMessageQuery>response.data.updateTipMessage;
  }
  async DeleteTipMessage(tipId?: number): Promise<DeleteTipMessageQuery> {
    const statement = `query DeleteTipMessage($tipId: Int) {
        deleteTipMessage(tipId: $tipId) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          result {
            __typename
            tipId
            messages
            clientId
            displayCount
            lastDisplayed
            subId
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (tipId) {
      gqlAPIServiceArguments.tipId = tipId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTipMessageQuery>response.data.deleteTipMessage;
  }
  async InviteFamilyMember(
    clientId?: string,
    poolId?: string,
    profiles?: Array<inviteFamilyMemberInputData | null>
  ): Promise<InviteFamilyMemberQuery> {
    const statement = `query InviteFamilyMember($clientId: String, $poolId: String, $profiles: [inviteFamilyMemberInputData]) {
        inviteFamilyMember(clientId: $clientId, poolId: $poolId, profiles: $profiles) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (clientId) {
      gqlAPIServiceArguments.clientId = clientId;
    }
    if (poolId) {
      gqlAPIServiceArguments.poolId = poolId;
    }
    if (profiles) {
      gqlAPIServiceArguments.profiles = profiles;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <InviteFamilyMemberQuery>response.data.inviteFamilyMember;
  }
  async GetSdohDomainTopics(
    pagination?: paginationField
  ): Promise<GetSdohDomainTopicsQuery> {
    const statement = `query GetSdohDomainTopics($pagination: paginationField) {
        getSdohDomainTopics(pagination: $pagination) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            domain
            topics{
              __typename
              name  
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSdohDomainTopicsQuery>response.data.getSdohDomainTopics;
  }

  async GetSdohZctaAHRQData(
    domainName?: string,
    topicsName?: string,
    zipCode?: string,
    pagination?: paginationField
  ): Promise<GetSdohZctaAHRQDataQuery> {
    const statement = `query GetSdohZctaAHRQData($domainName: String, $topicsName: String, $zipCode: String, $pagination: paginationField) {
        getSdohZctaAHRQData(
          domainName: $domainName
          topicsName: $topicsName
          zipCode: $zipCode
          pagination: $pagination
        ) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            variableName
            variableLabel
            value
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (domainName) {
      gqlAPIServiceArguments.domainName = domainName;
    }
    if (topicsName) {
      gqlAPIServiceArguments.topicsName = topicsName;
    }
    if (zipCode) {
      gqlAPIServiceArguments.zipCode = zipCode;
    }
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetSdohZctaAHRQDataQuery>response.data.getSdohZctaAHRQData;
  }

  async GetAllSdohZipCode(
    zipCode?: string,
    pagination?: paginationField
  ): Promise<GetAllSdohZipCodeQuery> {
    const statement = `query GetAllSdohZipCode($zipCode: String, $pagination: paginationField) {
        getAllSdohZipCode(zipCode: $zipCode, pagination: $pagination) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            code
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (zipCode) {
      gqlAPIServiceArguments.zipCode = zipCode;
    }
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAllSdohZipCodeQuery>response.data.getAllSdohZipCode;
  }

  async GetAccessQualityCare(
    zipCode?: string,
    pagination?: paginationField
  ): Promise<GetAccessQualityCareQuery> {
    const statement = `query GetAccessQualityCare($zipCode: String, $pagination: paginationField) {
        getAccessQualityCare(zipCode: $zipCode, pagination: $pagination) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            ZIPCODE
            STATE
            REGION
            ACS_PCT_MEDICAID_ANY_ZC
            ACS_PCT_MEDICAID_ANY_BELOW64_ZC
            ACS_PCT_MEDICARE_ONLY_ZC
            ACS_PCT_OTHER_INS_ZC
            ACS_PCT_PVT_EMPL_DRCT_ZC
            ACS_PCT_PVT_EMPL_DRCT_BELOW64_ZC
            ACS_PCT_PRIVATE_ANY_ZC
            ACS_PCT_PRIVATE_ANY_BELOW64_ZC
            ACS_PCT_PRIVATE_EMPL_ZC
            ACS_PCT_PRIVATE_EMPL_BELOW64_ZC
            ACS_PCT_PRIVATE_MDCR_ZC
            ACS_PCT_PRIVATE_MDCR_35_64_ZC
            ACS_PCT_PRIVATE_OTHER_ZC
            ACS_PCT_PRIVATE_OTHER_BELOW64_ZC
            ACS_PCT_PRIVATE_SELF_ZC
            ACS_PCT_PRIVATE_SELF_BELOW64_ZC
            ACS_PCT_PUBLIC_ONLY_ZC
            ACS_PCT_PUBLIC_OTHER_ZC
            ACS_PCT_PUBLIC_OTHER_BELOW64_ZC
            ACS_PCT_SELF_MDCR_ABOVE35_ZC
            ACS_PCT_TRICARE_VA_ZC
            ACS_PCT_TRICARE_VA_BELOW64_ZC
            ACS_PCT_UNINSURED_ZC
            ACS_PCT_UNINSURED_BELOW64_ZC
            HIFLD_DIST_UC_ZP
            POS_DIST_ED_ZP
            POS_DIST_MEDSURG_ICU_ZP
            POS_DIST_TRAUMA_ZP
            POS_DIST_PED_ICU_ZP
            POS_DIST_OBSTETRICS_ZP
            POS_DIST_CLINIC_ZP
            POS_DIST_ALC_ZP
            CEN_AIAN_NH_IND
            CCBP_TOT_HOME_ZP
            CDCP_ARTHRITIS_ADULT_C_ZC
            CDCP_ASTHMA_ADULT_C_ZC
            CDCP_BLOOD_MED_ADULT_C_ZC
            CDCP_CHOLES_ADULT_C_ZC
            CDCP_CHOLES_SCR_ADULT_C_ZC
            CDCP_DOCTOR_VISIT_ADULT_C_ZC
            CDCP_KIDNEY_DISEASE_ADULT_C_ZC
            CDCP_NO_PHY_ACTV_ADULT_C_ZC
            CDCP_PULMONARY_ADULT_C_ZC
            CDCP_CERVCAN_SCR_F21_65_C_ZC
            CDCP_DENTIST_VISIT_ADULT_C_ZC
            CDCP_FOBT_SIG_COL_5String_75_C_ZC
            CDCP_MAMMO_SCR_F5String_74_C_ZC
            CDCP_NO_TEETH_65_ABOVE_C_ZC
            CDCP_PREV_SERV_F65_ABOVE_C_ZC
            CDCP_PREV_SERV_M65_ABOVE_C_ZC
            CDCP_SLEEP_LESS7HR_ADULT_C_ZC
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (zipCode) {
      gqlAPIServiceArguments.zipCode = zipCode;
    }
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAccessQualityCareQuery>response.data.getAccessQualityCare;
  }
  async GetAccessQualityDescription(
    pagination?: paginationField
  ): Promise<GetAccessQualityDescriptionQuery> {
    const statement = `query GetAccessQualityDescription($pagination: paginationField) {
        getAccessQualityDescription(pagination: $pagination) {
          __typename
          status {
            __typename
            statusCode
            msg
          }
          response {
            __typename
            totalCount
            pageLimit
            page
            offset
            totalRecordView
          }
          result {
            __typename
            variableName
            variableLabel
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (pagination) {
      gqlAPIServiceArguments.pagination = pagination;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAccessQualityDescriptionQuery>(
      response.data.getAccessQualityDescription
    );
  }
}
