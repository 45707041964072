import { Injectable } from '@angular/core';
import { Analytics } from 'aws-amplify';
import { APIService } from '../API.service';
import { HttpClient } from '@angular/common/http';
import { DataTransferService } from '../service/data-transfer.service';
import { Auth} from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private dataTransfer:DataTransferService, private apiService: APIService, private http: HttpClient) { }
  saveUserLogs(json){
    if(json.userId){
      if(json.type=="loggedOut"){
        Auth.currentAuthenticatedUser().then(user=>{
          Auth.updateUserAttributes(user, {
            'custom:lastLoginTime': new Date(),
          }).then(data=>{
            console.log("data",data);
          });
        });
        let startFrom = new Date(localStorage.loggedInTime).getTime();
        let seconds = Math.round(new Date().getTime() - startFrom) / 1000;
        //var userLog = {type:"sessionDuration",userId:localStorage.currentSub,sessionDuration:seconds,dateTime:new Date()};
       // this.saveUserLogs(userLog);
        json.sessionDuration = seconds;
        this.apiService.GetSessionuserData("get",localStorage.currentSub,"","loggedOut").then(data=>{
          //console.log(data);
        }).catch(error=>{
          console.log(error);
        });
      }
      var userData = localStorage.userData?JSON.parse(localStorage.userData):{};
      if(json.type=="search" || json.type=="select"){
        userData.searchZipcode = localStorage.searchZipcode?localStorage.searchZipcode:userData.zipCode;
      }
      userData.userZipcode = userData.zipCode;
      delete userData.zipCode;
      var newJson = {...json, ...userData};
      if(json.type=="select"){
        var hyperUserData = localStorage.hyperUserData?JSON.parse(localStorage.hyperUserData):{};
        var childFlag = hyperUserData.childDependentFlag?.toLowerCase()=="n"?"NO":"YES"
        var elderFlag = hyperUserData.elderDependentFlag?.toLowerCase()=="n"?"NO":"YES"
        var language = hyperUserData.memberPreferredLanguage?hyperUserData.memberPreferredLanguage:"";
        var searchTerm = newJson.searchKey?newJson.searchKey:sessionStorage.providerGroup;
        var pageText = searchTerm+" "+newJson.searchZipcode;
        var npi = newJson.npi?newJson.npi:newJson.ccn;
        var doctorName = newJson.doctorName?newJson.doctorName:newJson.facilityName;
        var ntype = newJson.npi?"npi":"ccn";
        var searchData:any = {
            "data":[{
              "id":1,
              "pageContent":pageText,
              "metadata":{"name": doctorName,"npi":npi,"type":ntype,"sex":newJson.provGender,"elderDependence":elderFlag,"childDependence":childFlag,"language":language,"specialty":newJson.specialty}
            }]
        }
        this.apiService.GetHyperLocalSeachData("set",searchData).then(data=>{
          console.log(data);
        }).catch(error=>{
          console.log(error);
        });
      }
      var newJson1 = {...newJson}
      delete newJson1.provGender;
      this.apiService.UpdateUserTrailLogs(newJson1).then(data=>{
        //console.log(data);
      }).catch(error=>{
        console.log(error);
      });
      if(json.type=="loggedIn"){
        localStorage.loggedInTime = json.dateTime;
      }
    }
  }
  // getTreatmentCenter(json){
  //   return this.http.get("https://findtreatment.gov/locator/exportsAsJson/v2?"+json);
  // }
  getLatLong(cityState){
    var cityStates = cityState.split(",");
    //var json = '{"options":{},"location":{"street":"","city":"'+cityStates[0]+'","state":"'+cityStates[1]+'","postalCode":"","adminArea1":"US"}}';
    //return this.http.get("https://www.mapquestapi.com/geocoding/v1/address?key="+key+"&json="+json);
    const params = {
      city: cityStates[0],
      state: cityStates[1],
      format: 'json',
      addressdetails: '1',
      //countrycodes: 'us',
      limit: '1'
    };
    return this.http.get("https://nominatim.openstreetmap.org/search", { params });
  }
  /*pageViewEvent(url){
    Analytics.record({
      name: 'PageView', 
      attributes: { page: url }
    });
  }
  freetextSearchEvent(key){
    Analytics.record({
      name: 'FreeTextSearch', 
      attributes: { SearchKey : key }
    });
  }
  linkClickEvent(link){
    Analytics.record({
      name: 'LinkCliked', 
      attributes: { LinkName : link }
    });
  }
  filterEvent(name,value){
    var data={};
    data[name]=value;
    Analytics.record({
      name: 'Filter', 
      attributes: data
    });
  }
  LoggedIn(name){
    Analytics.record({
      name: 'LoggedIn', 
      attributes: { UserName : name }
    });
  }
  LoggedOut(name){
    Analytics.record({
      name: 'LoggedOut', 
      attributes: { UserName : name }
    });
  }
  detailsView(type, name){
    if(type == "NPI"){
      Analytics.record({
        name: 'DetailsScreen', 
        attributes: { "NPI" : name }
      });
    }else{
      Analytics.record({
        name: 'DetailsScreen', 
        attributes: { "CCN" : name }
      });
    }
  }*/
}
