// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import userpool from './userpoolmap.json';
import resetpasswordQuestion from './resetpassquestions.json';

export const environment = {
  production: false,
  ip_stack_api_key:"32505086f912635257a5b106f54629cb",
  //redirectUri:"https://release-dev.d2e1zdc6fb7y9w.amplifyapp.com/home",
  //signoutUri:"https://release-dev.d2e1zdc6fb7y9w.amplifyapp.com/",
  //tokenUri:"https://simbiq-saml-test.auth.us-east-1.amazoncognito.com/oauth2/token",
  userPool: userpool,
  resetpasswordQuestion:resetpasswordQuestion,
  isMobileApp: false,
  uiVersion:"3.0.3",
  openaiApiKey:"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",
  awsAccessKeyId:"AKIA4FODEYYBME6JQXNB",
  awsSecretAccessKey:"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",
  awsMailSender:"no-reply@simbiq.com",
  providerOSIndex:"providers-test-4",
  facilityOSIndex:"facilities-test-6",
  reviewGuidelines:{
    "bucketName":"simbiqusers-dev",
    "fileName":"review-guidelines.txt"
  },
  insuranceProvidersPath:{
    "bucketName":"simbiqusers-dev",
    "fileName":"InsuranceProvider.txt"
  },
  searchMapPath:{
    "bucketName":"simbiqusers-dev",
    "fileName":"searchWordMap.json"
  },
  aboutMeCMSAHCHRSN:"YES",
  restApiUrl:"https://tval3kklca.execute-api.us-east-1.amazonaws.com/dev/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
